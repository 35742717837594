import { SET_QUIZZES } from "@store/actions/games.actions";
import { RELOAD } from "@store/actions/global";
import { Quiz } from "src/games/types/Quiz";

interface state {
    quizzes: {
        docs: Quiz[];
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
}

const initialState: state = {
    quizzes: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
};

const gamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUIZZES:
            return {
                ...state,
                quizzes: {
                    docs: action.quizzes,
                    paginator: action.paginator,
                },
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default gamesReducer;
