import store from "@store/index";
import { hideScanner, loading, ready, showPopup } from "@store/actions/global";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";
import Wallet from "@core/wallet/Wallet";
import { ModuleControlService, Modules, Services } from "@core/services/ModuleControlService";
import { Alert } from "react-native";

export const isWalletConnect = (qrCode) => {
    const WalletConnectURL = /^wc:/i;
    switch (true) {
        case WalletConnectURL.test(qrCode):
            return getWalletConnect(qrCode);
        default:
            return;
    }
};

export const getWalletConnect = async (data?: string) => {
    store.dispatch(hideScanner());
    if (!ModuleControlService.getInstance().isServiceEnabled(Services.walletConnectService)) {
        return;
    }

    store.dispatch(loading());
    await waitForWalletSyncingToFinish();
    await WalletConnectV2Service.getInstance().pairDapp(data);
    store.dispatch(ready());
};

const waitForWalletSyncingToFinish = () => {
    return new Promise<void>((resolve) => {
        const checkSyncing = () => {
            const state = store.getState();
            if (state.wallet.syncing === false) {
                resolve();
            } else {
                setTimeout(checkSyncing, 1000);
            }
        };
        checkSyncing();
    });
};
