import { Dimensions } from "react-native";

export const getScale = () => {
    const { width, height } = Dimensions.get('window');
    const minDimension = Math.min(width, height);

    if (minDimension >= 1080) {
        return 1; // Pantallas grandes
    } else if (minDimension >= 720) {
        return 0.75; // Pantallas medianas
    } else {
        return 0.5; // Pantallas pequeñas (iPhone)
    }
}
