import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import {
    claimAsset,
    claimMembership,
    getAsset,
    getMembership
} from "@screens/scanner/implementations/ExperiencesImplementation";
import { getQuizGame } from "@screens/scanner/implementations/GamesImplementation";
import { isWalletConnect } from "@screens/scanner/implementations/WalletConnectImplementation";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { showScanner, showSnackbar } from "@store/actions/global";
import { setSelectedNFT } from "@store/actions/nfts.action";
import { setSelectedPost } from "@store/actions/social.actions";
import store from "@store/index";
import * as Linking from "expo-linking";
import { Platform, Share } from "react-native";
import ExperienceService from "./ExperienceService";
import NFTService from "./NFTService";

const { t } = i18n;

export default class ShareService {
    private static instance: ShareService;
    isLinkingInit: boolean = false;

    constructor() {}

    static getInstance(): ShareService {
        if (!ShareService.instance) {
            ShareService.instance = new ShareService();
        }
        return ShareService.instance;
    }

    handleLinking = (navigation: NavigationType) => {
        if (!this.isLinkingInit) {
            this.isLinkingInit = true;

            // App closed
            if (Platform.OS == "android") {
                const NativeIntent = require("react-native/Libraries/Linking/NativeIntentAndroid").default;

                NativeIntent.getInitialURL().then((url) => {
                    const { queryParams } = Linking.parse(url);
                    
                    this.redirectLinking(queryParams, navigation, url);
                });
            } else {
                Linking.parseInitialURLAsync().then((url) => {
                    const { queryParams } = url;
                    this.redirectLinking(queryParams, navigation, url);
                });
            }

            // App in the background
            if (Platform.OS != "web")
                Linking.addEventListener("url", ({ url }) => {
                    const { queryParams } = Linking.parse(url);
                  
                    this.redirectLinking(queryParams, navigation, url);
                });
        }
    };

    redirectLinking = async (queryParams: any, navigation, url) => {
        if (queryParams?.uri || url) {
            isWalletConnect(queryParams?.uri ?? url);
            return;
        }
        if (queryParams?.id) {
            switch (queryParams?.type) {
                case "nft":
                    const updatedNft = await NFTService.getInstance().getNFT(
                        queryParams?.id,
                        queryParams?.blockchain,
                        true
                    );
                    store.dispatch(setSelectedNFT(updatedNft));
                    navigation.navigate("NFTs");
                    navigation.navigate("NFTs", {
                        screen: "NFT",
                    });
                    break;

                case "post":
                    const updatedPost = await SocialNetworkService.getInstance().getPostById(queryParams?.id);
                    store.dispatch(setSelectedPost(updatedPost));
                    navigation.navigate("SocialNetwork");
                    navigation.navigate("SocialNetwork", {
                        screen: "PostDetail",
                    });
                    break;

                case "experience":
                    const experience = await ExperienceService.getInstance().getExperience(queryParams?.id);
                    navigation.navigate("Experience", {
                        screen: "ExperienceDetail",
                        params: { experience },
                    });
                    break;

                case "profile":
                    navigation.navigate("Profile", {
                        screen: "ProfileMain",
                        params: { clientID: queryParams?.id },
                    });
                    break;

                case "asset":
                    getAsset(navigation, queryParams?.id);
                    break;

                case "asset.option":
                    claimAsset(navigation, queryParams?.id);

                    break;

                case "membership":
                    getMembership(navigation, queryParams?.id);
                    break;

                case "membership.option":
                    claimMembership(navigation, queryParams?.id);
                    break;
                case "quiz":
                    getQuizGame(navigation, queryParams?.id);
                    break;

                default:
                    store.dispatch(showScanner());
                    break;
            }
        }
    };

    handleShare = async (message: string, redirectLink: string) => {
        if (Platform.OS == "web") {
            navigator.clipboard.writeText(`${message} ${redirectLink}`);
            store.dispatch(showSnackbar({ type: "SUCCESS", message: t("link_copied") }));
        } else {
            try {
                await Share.share({
                    message: `${message} ${Platform.OS != "ios" ? redirectLink : ""} `,

                    url: redirectLink,
                });
            } catch (error: any) {
                store.dispatch(
                    showSnackbar({
                        type: "ERROR",
                        message: error.message,
                    })
                );
            }
        }
    };
}
