import { FiatCurrency } from "@core/fiat/FiatCurrency";
import DataService from "@core/services/DataService";
import { PathNode } from "@core/utils/PathNode";
import Wallet from "@core/wallet/Wallet";
import SwapType from "@custom-types/SwapType";
import TransactionType from "@custom-types/TransactionType";
import BigNumber from "bignumber.js";
import { Network } from "bitcoinjs-lib";
import HDNode from "hdkey";
import AbstractCurrency from "./AbstractCurrency";
import CurrencyImplementation from "./CurrencyImplementation";
import { ApiService } from "@core/services/ApiService";
import { DigitalCurrenciesService } from "@core/services/DigitalCurrenciesService";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import { Client } from "@custom-types/Client";
import { showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import DigitalCurrencyTransactionType from "@custom-types/DigitalCurrencyTransactionType";
import ActivityService from "@core/services/ActivityService";
import { ActivityModel } from "@custom-types/ActivityModel";
import createHash from 'create-hash';

export interface CurrencyStore {
    name: string;
    account: string;
    change: string;
    index: string;
    smart: boolean;
    enabled: boolean;
}

interface Adapter {
    xo?: string;
    _id?: string;
}

interface ICurrency {
    _id?: string;
    id?: string;
    name?: string;
    symbol?: string;
    fullName?: string;
    decimals?: number;
    seed?: Buffer;
    implementation?: any;
    adapters?: Adapter;
    networkID?: number;
    color?: string;
    icon?: string;
    networkSymbol?: string;
    fiatCurrency?: FiatCurrency;
    fiat?: number;
    testnet?: boolean;
    network?: Network;
    account?: string;
    change?: string;
    index?: string;
    provider?: string;
    underlyingCurrency?: string;
    chainId?: number;
    smartAllowed?: boolean;
    smart?: boolean;
    address?: string;
    providers?: Array<string>;
    enabled?: boolean;
    walletConnect?: boolean;
    blockchain?: string;
    nftSupport?: boolean;
    ethCompatibilitySupport?: boolean;
    kind?: string;
    priceHistoryDaily?: [];
    priceHistoryWeekly?: [];
    type?: string;
    pairs?: Array<string>;
    explorerUrl?: string;
}

export interface IConstructorCurrency {
    id?: string;
    seed?: Buffer;
    fiatCurrency?: FiatCurrency;
    fiat?: number;
    testnet?: boolean;
    account?: string;
    adapters?: Adapter;
    change?: string;
    index?: string;
    address?: string;
    enabled?: boolean;
    ethCompatibilitySupport?: boolean;
    name?: string;
    symbol?: string;
    fullName?: string;
    decimals?: number;
    color?: string;
    icon?: string;
    blockchain?: string;
    chainId?: number;
    network?: string;
    kind?: string;
    priceHistoryDaily?: [];
    priceHistoryWeekly?: [];
    type?: string;
    sort?: number;
    underlyingCurrency?: string;
    pairs?: Array<string>;
    explorerUrl?: string;
}

const defaultAccount = "0";
const defaultChange = "0";
const defaultIndex = "0";

export default class Currency extends AbstractCurrency {
  protected color: string;
  protected implementation: CurrencyImplementation;
  protected networkID: number;
  protected seed: Buffer;
  protected fiatCurrency: FiatCurrency;
  private dataService: DataService;
  private apiService: ApiService;
  private hdNode: HDNode;
  private addressCounter: number = 1;
  private balance = 0;
  private unconfirmedBalance = 0;
  private fiat = 0;
  private priceHistoryDaily = [];
  private priceHistoryWeekly = [];
  private pairs;
  private testnet = false;
  private networkNode = null;
  private accountNode = null;
  private network: Network;
  private transactions: Array<any>;
  private account: string;
  private change: string;
  private index: string;
  private provider = null;
  private underlyingCurrency = null;
  private chainId: number;
  private smartAllowed: boolean;
  private smart: boolean;
  private addresses: Array<{ [key: string]: string }>;
  private enabled: boolean;
  private ethCompatibilitySupport: boolean;
  private explorerUrl: string;
  private providers = [];
  private purchaseOrders: Array<any>;
  private formats = [];
  private approving = false;
  private walletConnect = false;
  private blockchain: string;
  private nftSupport = false;
  private kind: string = "";
  private type: string = "";
  private adapters: Adapter = {};
  private activity: ActivityModel[];

  constructor(parameters: ICurrency) {
    super({
      id: parameters.id ? parameters.id : parameters.name,
      name: parameters.name,
      symbol: parameters.symbol,
      icon: parameters.icon,
      fullName: parameters.fullName,
      decimals: parameters.decimals,
    });

    this.seed = parameters.seed;
    this.networkID = parameters.networkID;
    this.color = parameters.color;
    this.icon = parameters.icon;
    this.network = parameters.network;
    this.hdNode = HDNode.fromMasterSeed(parameters.seed, this.network.bip32);
    this.implementation = new parameters.implementation(this);
    this.dataService = new DataService();
    this.fiatCurrency = parameters.fiatCurrency;
    this.adapters = parameters.adapters || {};
    this.fiat = parameters.fiat;
    this.testnet = parameters.testnet;
    this.providers = parameters.providers || [];
    this.transactions = [];
    this.purchaseOrders = [];
    this.account = parameters.account || defaultAccount;
    this.change = parameters.change || defaultChange;
    this.index = parameters.index || defaultIndex;
    this.provider = parameters.provider || "";
    this.underlyingCurrency = parameters.underlyingCurrency || this.name;
    this.chainId = parameters.chainId;
    this.smart = parameters.smart || false;
    this.smartAllowed = parameters.smartAllowed || false;
    this.enabled = parameters.enabled;
    this.ethCompatibilitySupport = parameters.ethCompatibilitySupport || false;
    this.addresses = [];
    this.walletConnect = parameters.walletConnect || false;
    this.blockchain = parameters.blockchain || null;
    this.nftSupport = parameters.nftSupport || false;
    this.kind = parameters.kind || "";
    this.priceHistoryDaily = parameters.priceHistoryDaily || [];
    this.priceHistoryWeekly = parameters.priceHistoryWeekly || [];
    this.type = parameters.type;
    this.pairs = parameters.pairs;
    this.explorerUrl = parameters.explorerUrl || "";
    this.activity = [];
  }

  getSeed() {
    return this.seed;
  }

  isSmartAllowed() {
    return this.smartAllowed;
  }

  isSmart() {
    return this.smartAllowed && this.smart;
  }

  setSmart(smart: boolean) {
    this.smart = smart;
  }

  setApproved(approving: boolean) {
    this.approving = approving;
  }

  isApproving(): boolean {
    return this.approving;
  }

  getFormats(): Array<string> {
    return this.implementation.getFormats();
  }

  isMultiFormat() {
    return this.implementation.getFormats().length > 0;
  }

  getUnderlyingCurrency(): Currency {
    return Wallet.getInstance().findCurrencyByBlockchain(
      this.blockchain,
      this.isTestnet()
    );
  }

  getBlockchain(): string {
    return this.blockchain || this.getUnderlyingCurrency().getBlockchain();
  }

  getExplorerUrl() {
    return this.explorerUrl;
  }

  getUnderlyingCurrencyID() {
    return this.underlyingCurrency;
  }

  getPName(): string {
    return (this.isTestnet() ? "t" : "") + this.name?.toUpperCase();
  }
  getPFullName(): string {
    return super.getName() + (this.isTestnet() ? " (Testnet)" : "");
  }

  getAccount() {
    return this.account;
  }

  setAccount(account: string) {
    this.account = account;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }

  async setAsyncEnabled(enabled: boolean) {
    this.enabled = enabled;
    this.enabled = await DigitalCurrenciesService.setEnabledCurrency(
      this,
      enabled
    );
  }

  getChange() {
    return this.change;
  }

  setChange(change: string) {
    this.change = change;
  }

  getIndex() {
    return this.index;
  }

  getType() {
    return this.type;
  }

  setIndex(index: string) {
    this.index = index;
  }

  getNetworkID() {
    // if (this.hasEthCompatibility()) {
    //     return Wallet.getInstance().findCurrencyById("ethereum.mainnet.native.eth").getNetworkID();
    // }
    // if (this.underlyingCurrency && this.underlyingCurrency !== this.id) {
    //     return Wallet.getInstance().findCurrencyById(this.underlyingCurrency).getNetworkID();
    // }
    return this.networkID;
  }

  getAdapter(adapter: keyof Adapter) {
    return this.adapters?.[adapter];
  }

  getStaticNetworkID() {
    return this.networkID;
  }

  hasEthCompatibility() {
    const client: Client = store.getState().auth.client;
    return client?.ethCompatibility && this.ethCompatibilitySupport;
  }

  hasEthCompatibilitySupport() {
    return this.ethCompatibilitySupport;
  }

  getImplementation() {
    return this.implementation;
  }

  getFullName(): string {
    return super.getFullName();
  }

  getNetwork() {
    return this.network;
  }

  getProvider() {
    return this.testnet ? this.provider.testnet : this.provider.mainnet;
  }

  isTestnet() {
    return this.testnet;
  }

  isBuyable() {
    return this.providers.some((element) => {
      const providers = ModuleControlService.getInstance().getProviders();
      return providers && providers?.includes(element);
    });
  }

  isBuyableBy(provider?: string) {
    const providers = ModuleControlService.getInstance().getProviders();
    return providers.includes(provider) && this.providers.includes(provider);
  }

  isSellable() {
    return this.providers.some((element) => {
      const providers = ModuleControlService.getInstance().getProviders();
      return providers && providers?.includes(element);
    });
  }

  isSellableBy(provider?: string) {
    const providers = ModuleControlService.getInstance().getProviders();
    return providers.includes(provider) && this.providers.includes(provider);
  }

  getChainId() {
    return this.chainId;
  }

  hasWalletConnect() {
    return this.walletConnect;
  }

  hasNftSupport() {
    return this.nftSupport;
  }

  getNetworkType() {
    return this.testnet ? "testnet" : "mainnet";
  }

  getKind() {
    return this.kind;
  }

  getColor() {
    return this.color || "#ffffff";
  }

  getSymbol(): string {
    return this.symbol;
  }

  getNetworkSymbol(): string {
    return this.getKind() !== "NATIVE" && this.blockchain;
  }
  isValidAddress(address: string) {
    return this.implementation.isValidAddress(address);
  }

  parseTransaction = (tx: Object): DigitalCurrencyTransactionType => {
    return this.implementation.parseTransaction(tx);
  };

  getTransactions() {
    return this.transactions;
  }

  getTransaction(index: number) {
    return this.transactions[index];
  }

  async getTransactionByHash(txId: string) {
    return await DigitalCurrenciesService.getInstance().getTransaction(
      this,
      txId
    );
  }

  setTransactions(transactions: Array<any>) {
    this.transactions = transactions;
    return this;
  }

  getPurchaseOrders() {
    return this.purchaseOrders;
  }

  getPurchaseOrder(index: number) {
    return this.purchaseOrders[index];
  }

  setPurchaseOrders(orders: Array<any>) {
    this.purchaseOrders = orders;
    return this;
  }

  toFiat(amount: number) {
    const n = this.fiat * amount;
    return n.toFixed(n < 0.01 && n > 0 ? 3 : 2);
  }

  getFiat(): number {
    return this.fiat;
  }

  // setFiat(value: number) {
  //     this.fiat = value;
  //     return this;
  // }

  setPriceHistoryDaily(prices: any) {
    // this.priceHistoryDaily = prices;
    // return this;
  }

  setPriceHistoryWeekly(prices: any) {
    // this.priceHistoryWeekly = prices;
    // return this;
  }

  getPriceHistoryDaily() {
    return this.priceHistoryDaily;
  }

  getPriceHistoryWeekly() {
    return this.priceHistoryWeekly;
  }

  getPair(id: string) {
    // const pairs = this.getPairs();
    // if (pairs) return pairs.find((pair) => pair.toUpperCase() == id.toUpperCase());
  }

  getPairs(): Array<{
    _id: string;
    targetCurrency: string;
    enabled?: boolean;
    platform?: string;
  }> {
    return this.pairs;
  }

  isExchangeAvailable() {
    return (
      ModuleControlService.getInstance().isServiceEnabled(
        Services.exchangeService
      ) && this.getPairs().length > 0
    );
  }

  setPairs(pairs) {
    this.pairs = pairs;
    return this;
  }

  getBalance() {
    return this.balance;
  }

  setBalance(value: number) {
    this.balance = value;
    return this;
  }

  getUnconfirmedBalance() {
    return this.unconfirmedBalance;
  }

  setUnconfirmedBalance(value: number) {
    this.unconfirmedBalance = value;
    return this;
  }

  getFormatBalance() {
    return this.format(this.balance);
  }

  getFormatTotalBalance() {
    const balance = new BigNumber(this.balance);
    const unconfirmedBalance = new BigNumber(this.unconfirmedBalance);
    return this.format(balance.plus(unconfirmedBalance).toNumber());
  }

  getUnconfirmedFiatBalance() {
    return this.fromDecimals(this.unconfirmedBalance) * this.fiat || 0;
  }

  getFiatBalance() {
    return this.fromDecimals(this.balance) * this.fiat || 0;
  }

  getFiatTotalBalance() {
    return this.getFiatBalance() + this.getUnconfirmedFiatBalance();
  }

  getAddress(options?: { path?: string; owner?: boolean; format?: string }) {
    const path = options?.path || this.getCurrentPath();
    if (!(this.addresses && this.addresses[path])) {
      let chainId = this.chainId;
      if (this.name !== this.underlyingCurrency) {
        const u = this.getUnderlyingCurrency();

        chainId = u.chainId;
        return u.getAddress({ path: path });
      }
      const addr = this.implementation.generateAddress(
        this.generateAddressNode(path),
        {
          chainId: chainId,
          owner: options ? options.owner : false,
          format: options?.format,
        }
      );
      this.addresses[path] = addr;
      return addr;
    }

    return this.addresses[path];
  }

  getAddressesForAllFormats() {
    if (this.isMultiFormat()) {
      return this.getFormats()
        .map((format) => this.getAddress({ format: format }))
        .join(",");
    }
    return this.getAddress();
  }

  isEnabled() {
    return this.enabled;
  }

  getPublicKey() {
    const pathNode = this.generateAddressNode();
    return pathNode.node.xpub;
  }

  getCurrentPath(purpose = "44") {
    return `m/${purpose}'/${this.getNetworkID()}'/${this.account}'/${
      this.change
    }/${this.index}`;
  }

  getDefaultPath() {
    return `m/44'/${this.getNetworkID()}'/${defaultAccount}'/${defaultChange}/${defaultIndex}`;
  }

  generateAddressNode(path?: string) {
    if (!path) {
      path = this.getCurrentPath();
    }
    return new PathNode(path, this.hdNode.derive(path).toJSON());
  }

  getReceiveAddress(options?: {
    path?: string;
    owner?: boolean;
    format?: string;
  }) {
    return `${this.getName().toLowerCase()}:${this.getAddress(options)}`;
  }

  loadStateFromStore(): Promise<any> {
    return Promise.resolve(null);
  }

  async newTransaction(transaction: TransactionType) {
    return await DigitalCurrenciesService.getInstance().getSkeleton(
      this,
      transaction
    );
  }

  async newSwap(swap: SwapType) {
    return await DigitalCurrenciesService.getInstance().newSwap({
      fromCurrency: this.getId(),
      toCurrency: swap.to.getId(),
      amount: this.toDecimals(swap.amount).toString(),
      publicKey: swap.publicKey,
      preImageHash: swap.preImageHash,
      nonce: swap.nonce,
    });
  }

  async approveSwap(swap: Partial<SwapType>) {
    return await DigitalCurrenciesService.getInstance().approveSwap({
      fromCurrency: this.getId(),
      toCurrency: swap.to.getId(),
    });
  }

  async sendTransaction(skeleton) {
    const signedTransaction = await this.signTransaction(skeleton);
    return await DigitalCurrenciesService.getInstance().sendBroadCast(
      signedTransaction,
      skeleton
    );
  }

  async signTransaction(skeleton) {
    const addressNode = this.generateAddressNode();
    return await this.implementation.signTransaction(addressNode, skeleton);
  }

  async signMessage(hexMessage: string, path?: string) {
    const addressNode = this.generateAddressNode(path);
    return await this.implementation.signMessage(addressNode, hexMessage);
  }

  async signTypedData(JsonData: string) {
    const addressNode = this.generateAddressNode();
    return await this.implementation.signTypedData(addressNode, JsonData);
  }

  async syncBalance() {
    try {
      const res: any = await DigitalCurrenciesService.getInstance()
        .syncBalance(this)
        .catch((e) => {
          return {
            data: {
              balance: 0,
              unconfirmedBalance: 0,
              transactions: [],
            },
          };
        });

      if (res?.data?.error) {
        store.dispatch(
          showPopupMessage({
            type: "WARNING",
            title: `${this.getSymbol()} (${this.getBlockchain()?.toUpperCase()})`,
            message: `${res?.data?.error?.message}`,
          })
        );
      }

      if (
        this.getBalance() != Number(res.data?.balance) ||
        this.getUnconfirmedBalance() != Number(res.data?.unconfirmedBalance)
      ) {
        this.setBalance(res.data?.balance ? Number(res.data?.balance) : 0);
        this.setUnconfirmedBalance(
          res.data?.unconfirmedBalance
            ? Number(res.data?.unconfirmedBalance)
            : 0
        );
      }
    } catch (e) {}

    return this;
  }

  async syncTransactions() {
    try {
      const res = await DigitalCurrenciesService.getInstance().getTransactions(
        this
      );
      this.setTransactions(res.data ? res.data : []);
    } catch (e) {}

    return this;
  }

  async syncActivity() {
    const res = await ActivityService.getInstance().getActivityByCurrency(
      this.getId()
    );
    this.setActivity(res ? res : []);
  }

  setActivity(activity: ActivityModel[]) {
    this.activity = activity;
    return this;
  }

  getActivity() {
    return this.activity;
  }

  getKeys() {
    const keys = this.implementation.getKeys(
      this.generateAddressNode(this.getDefaultPath())
    );
    return keys;
  }

  public toJSON(): CurrencyStore {
    return {
      name: this.getId(),
      account: this.getAccount(),
      change: this.getChange(),
      index: this.getIndex(),
      smart: this.isSmart(),
      enabled: this.isEnabled(),
    };
  }

  setFromJSON(data: Partial<CurrencyStore>) {
    this.setAccount(data?.account || defaultAccount);
    this.setChange(data?.change || defaultChange);
    this.setIndex(data?.index || defaultIndex);
    this.setSmart(data?.smart || false);
    this.setEnabled(data?.enabled || false);
  }

  getPreImage(index = 1): string {
    const addressNode = this.generateAddressNode(`m/999/0/0/0/${index}`);
    const xpub = addressNode.node.xpub;
    const preimageString = `${xpub}-${index}`;

    const preimageArray = new TextEncoder().encode(preimageString);

    if (preimageArray.length < 32) {
      const preimagePadded = new Uint8Array(32);
      preimagePadded.set(preimageArray); // Copiar el contenido
      return `0x${Buffer.from(preimagePadded).toString("hex")}`;
    } else if (preimageArray.length > 32) {
      const preimageTruncated = preimageArray.slice(0, 32);
      return `0x${Buffer.from(preimageTruncated).toString("hex")}`;
    }

    return `0x${Buffer.from(preimageArray).toString("hex")}`;
  }

  getPreImageHash(index = 1): string {
    const preimage = this.getPreImage(index);

    const preimageHash = createHash("sha256")
      .update(Buffer.from(preimage.slice(2), "hex")) // Convertir el string hexadecimal
      .digest("hex");

    return `${preimageHash}`;
  }

  async getExchangeNonce(toDigitalCurrency: Currency) {
    return await DigitalCurrenciesService.getInstance().getExchangeNonce({
      fromCurrency:this.getId(),
      toCurrency: toDigitalCurrency.getId()
    });
  }
}
