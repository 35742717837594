import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import LottieBase from "@components/animations/Lottie";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { AnimationObject } from "lottie-react-native";
import React, { Component } from "react";
import { StyleSheet, ViewStyle } from "react-native";

interface Props {
    title: string;
    style?: ViewStyle;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    boldText?: boolean;
    disabled?: boolean;
    color?: string;
    border?: boolean;
    vibrate?: boolean;
    animation?: AnimationObject | false;
    selected?: boolean;
    onPress: () => void;
}

interface State {}

export default class GameButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <PressableBase
                onPress={this.props.onPress}
                accessibilityLabel="radius-button"
                vibrate={this.props.vibrate}
                disabled={this.props.disabled}
                style={[
                    styles.defaultStyles,
                    {
                        backgroundColor: this.props.selected
                            ? getColorOpacity(colors.secondary, 0.95)
                            : getColorOpacity(colors.grey, 0.6),
                    },
                    this.props.style,
                ]}
            >
                {this.props.animation && (
                    <PressableBase
                        onPress={this.props.onPress}
                        vibrate={true}
                        style={{
                            position: "absolute",
                            top: -62,
                            right: -30,
                            zIndex: 999,
                            opacity: 0.95,
                            height: 130,
                            width: 130,
                            overflow: "visible",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <LottieBase
                            width={130}
                            height={130}
                            loop={true}
                            source={this.props.animation}
                        />
                    </PressableBase>
                )}
                {this.props.boldText ? (
                    <SemiBoldText
                        numberOfLines={1}
                        fontSize={this.props.fontSize || 16}
                        color={colors.white}
                        align="center"
                    >
                        {this.props.title}
                    </SemiBoldText>
                ) : (
                    <RegularText
                        numberOfLines={1}
                        fontSize={this.props.fontSize || 16}
                        color={colors.white}
                        align="center"
                    >
                        {this.props.title}
                    </RegularText>
                )}
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        width: "100%",
        padding: 15,
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
    },
});
