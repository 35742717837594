import Container from "@base/Container";
import Header from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { Client } from "@custom-types/Client";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import QuizGame from "@games/core/QuizGame";
import { Quiz } from "@games/types/Quiz";
import i18n from "@i18n/i18n";
import { Route } from "@react-navigation/native";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import React, { Component } from "react";
import { connect } from "react-redux";
import ShareService from "@core/services/ShareService";
import { ModuleControlService } from "@core/services/ModuleControlService";
import { Platform } from "react-native";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { quiz: Quiz }>;
}

interface State {
    quizGame: QuizGame;
    viewQuizGame;
}

const { t } = i18n;

export class _QuizScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onDisplay = this.onDisplay.bind(this);
        this.state = {
            quizGame: null,
            viewQuizGame: null,
        };
    }

    componentDidMount() {
        this.props.navigation.addListener("focus", this.onDisplay);
    }

    async onDisplay() {
        const quizGame = this.props.route?.params?.quiz
            ? new QuizGame({ quiz: this.props.route?.params?.quiz, navigation: this.props.navigation })
            : null;

        this.setState({
            quizGame,
            viewQuizGame: quizGame?.getViewGame(),
        });
        quizGame?.handleGameStatusChange(() => this.setState({ viewQuizGame: this.state.quizGame?.getViewGame() }));
    }

    handleShare = async () => {
        const url = `${ModuleControlService.getInstance().getShareUrl()}?type=quiz&id=${this.state?.quizGame?.getId()}`;
        ShareService.getInstance().handleShare(
            `${this.state?.quizGame?.getTitle()} (By ${this.state.quizGame?.getMerchant()?.name})`,
            url
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.quizGame?.getTitle(), 15)}
                    type={HeaderType.Light}
                    rightBtn={[
                        {
                            onPress: () => {
                                this.handleShare();
                            },
                            icon: "share",
                            size: 20,
                        },
                    ]}
                    {...this.props}
                />
                {this.state.quizGame && (
                    <Container style={[{ flex: 1, overflow: "hidden"  }]}>
                        {this.state.quizGame?.getBackground()}
                        {this.state.viewQuizGame}
                    </Container>
                )}
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const QuizScreen = connect(mapStateToProps, mapDispatchToProps)(_QuizScreen);

export default QuizScreen;
