import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import TransactionType from "@custom-types/TransactionType";
import i18n from "@i18n/i18n";
import { hideModalBottom, loading, ready, showPopup } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { vibrate, VibrationType } from "@utils/vibration/vibration.helper";
import EtherSwapArtifact from "boltz-core/out/EtherSwap.sol/EtherSwap.json";
import { Contract, ethers } from "ethers";
import * as Clipboard from "expo-clipboard";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { ExplorerComponent } from "../transaction/ExplorerComponent";

interface Props {
    fiatCurrency?: FiatCurrency;
    client: Client;
    rawData;
    loading: boolean;
    onPressCallBack?: () => void;
}

interface State {
    fromDigitalCurrency: Currency;
    toDigitalCurrency: Currency;
    swapData: any;
    copyData: string;
}

const { t } = i18n;

export class _ExchangeDetailComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fromDigitalCurrency: Wallet.getInstance().findCurrencyById(
                this.props.rawData?.data?.fromDigitalCurrency || ""
            ),
            toDigitalCurrency: Wallet.getInstance().findCurrencyById(this.props.rawData?.data?.toDigitalCurrency || ""),
            swapData: this.props.rawData?.data,
            copyData: "",
        };
    }

    onPressCopy = async (data) => {
        await Clipboard.setStringAsync(data);
        this.setState({ copyData: data });
        setTimeout(() => {
            this.setState({ copyData: "" });
        }, 3000);
    };

    handleClaim = async () => {
        vibrate(VibrationType.WARNING);
        store.dispatch(loading());

        const currency = this.state.toDigitalCurrency;
        const { extra } = this.state.swapData;
        const nonce = extra.extraTransactionData?.nonce;
        const preimage = this.state.fromDigitalCurrency.getPreImage(nonce);
        const amount = ethers.BigNumber.from(extra.extraTransactionData?.toAmount);
        const lockupAddress = extra.extraTransactionData?.lockupAddress;
        const timelock = ethers.BigNumber.from(
            extra.extraTransactionData?.timeoutBlockHeight.toString()
        );
        const refundAddress = extra.extraTransactionData?.refundAddress;
        const iface = new ethers.utils.Interface(EtherSwapArtifact.abi);
        const data = iface.encodeFunctionData("claim(bytes32,uint256,address,uint256)", [
            preimage,
            amount,
            refundAddress,
            timelock,
        ]);

        const transaction: TransactionType = {
            currency,
            amount: 0,
            addressFrom: refundAddress,
            addressTo: lockupAddress,
            data,
        };

        const res = await currency.newTransaction(transaction);
        await currency.sendTransaction(res.data);
        store.dispatch(ready());
        store.dispatch(hideModalBottom());
        this?.props?.onPressCallBack ? this.props.onPressCallBack() : null;
        store.dispatch(showPopup({ type: "SUCCESS", message: t("transaction_success") }));

    };

    render() {
        return (
            <>
                {this.props.rawData?.data && (
                    <View style={{ paddingBottom: 30 }}>
                        <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                            <Row style={{ alignItems: "center" }}>
                                <CurrencyIcon
                                    styles={{ marginRight: 15 }}
                                    iconSize={35}
                                    size={50}
                                    currency={this.state.fromDigitalCurrency}
                                />
                                <View>
                                    <Row style={{ alignItems: "center" }}>
                                        <BoldText fontSize={22}>
                                            {`${this.state.fromDigitalCurrency.format(
                                                Number(this.state.swapData.amount)
                                            )} ${this.state.fromDigitalCurrency.getSymbol()}`}
                                        </BoldText>
                                        <NetworkCurrencySymbol
                                            currency={this.state.fromDigitalCurrency}
                                        ></NetworkCurrencySymbol>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <SemiBoldText
                                            style={{
                                                backgroundColor: colors.secondary,
                                                paddingHorizontal: 8,
                                                paddingVertical: 3,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                opacity: 0.8,
                                                marginRight: 5
                                            }}
                                            fontSize={10}
                                            color={colors.white}
                                        >
                                            {"SWAP"}
                                        </SemiBoldText>
                                        {this.props.rawData?.createdAt !== undefined && (
                                            <RegularText fontSize={12} color={colors.grey}>
                                                {this.state.fromDigitalCurrency
                                                    .getImplementation()
                                                    .parseDate(this.props.rawData?.createdAt)}
                                            </RegularText>
                                        )}
                                    </Row>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 10 }}>{t("status")}</RegularText>

                            {this.state.swapData?.status == "READY_TO_CLAIM" && (
                                <Row style={{ justifyContent: "space-between" }}>
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            alignContent: "flex-start"
                                        }}
                                    >
                                        <View style={{ width: 40 }}>
                                            <LottieBase
                                                height={32}
                                                width={32}
                                                loop={true}
                                                source={require("../../../assets/animations/success.json")}
                                            />
                                        </View>

                                        <BoldText fontSize={16}>{t("ready_to_claim")}</BoldText>
                                    </Row>
                                    <PressableBase
                                        onPress={this.handleClaim}
                                        style={{
                                            backgroundColor: colors.secondary,
                                            paddingHorizontal: 12,
                                            paddingVertical: 2,
                                            borderRadius: 18,
                                            overflow: "hidden",
                                            opacity: 0.8,
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Row
                                            style={{
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <RegularText fontSize={14} color={colors.white}>
                                                {t("claim")}
                                            </RegularText>
                                            <Icon
                                                style={{ paddingLeft: 5 }}
                                                size={14}
                                                name="right"
                                                color={colors.white}
                                            ></Icon>
                                        </Row>
                                    </PressableBase>
                                </Row>
                            )}

                            {this.state.swapData?.status == "CLAIMING" && (
                                <Row
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start"
                                    }}
                                >
                                    <View
                                        style={{ width: 50, marginLeft: -15, marginVertical: -15 }}
                                    >
                                        <LottieBase
                                            height={64}
                                            width={64}
                                            loop={true}
                                            source={require("../../../assets/animations/pending.json")}
                                        />
                                    </View>

                                    <BoldText fontSize={16}>{t("claming")}</BoldText>
                                </Row>
                            )}

                            {this.state.swapData?.status == "DONE" && (
                                <Row
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start"
                                    }}
                                >
                                    <View style={{ width: 40 }}>
                                        <LottieBase
                                            height={32}
                                            width={32}
                                            loop={true}
                                            source={require("../../../assets/animations/success.json")}
                                        />
                                    </View>

                                    <BoldText fontSize={16}>{t("confirmed")}</BoldText>
                                </Row>
                            )}

                            {(this.state.swapData?.status == "FAILED" ||
                                this.state.swapData?.status == "INVALID") && (
                                <Row
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start"
                                    }}
                                >
                                    <View style={{ width: 40 }}>
                                        <LottieBase
                                            height={32}
                                            width={32}
                                            loop={true}
                                            source={require("../../../assets/animations/error.json")}
                                        />
                                    </View>

                                    <BoldText fontSize={16}>{t("error")}</BoldText>
                                </Row>
                            )}

                            {(this.state.swapData?.status == "PENDING" ||
                                this.state.swapData?.status == "IN_PROGRESS") && (
                                <Row
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start"
                                    }}
                                >
                                    <View
                                        style={{ width: 50, marginLeft: -15, marginVertical: -15 }}
                                    >
                                        <LottieBase
                                            height={64}
                                            width={64}
                                            loop={true}
                                            source={require("../../../assets/animations/pending.json")}
                                        />
                                    </View>

                                    <BoldText fontSize={16}>{t("pending")}</BoldText>
                                </Row>
                            )}

                            {this.state.swapData?.status == "REFUNDED" && (
                                <Row
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start"
                                    }}
                                >
                                    <View style={{ width: 50, marginRight: -5, marginLeft:-10 }}>
                                        <LottieBase
                                            height={24}
                                            width={24}
                                            loop={true}
                                            source={require("../../../assets/animations/refunded.json")}
                                        />
                                    </View>

                                    <BoldText fontSize={16}>{t("refunded")}</BoldText>
                                </Row>
                            )}
                        </View>

                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>
                                {t("transaction_detail")}
                            </RegularText>
                            <Row style={{ justifyContent: "space-between", paddingBottom: 10 }}>
                                <RegularText color={colors.grey} style={{ marginBottom: 15 }}>
                                    {t("amount")}
                                </RegularText>
                                <View
                                    style={{
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end"
                                    }}
                                >
                                    <CurrencyBalanceIcon
                                        currency={this.state.fromDigitalCurrency}
                                        fontSize={16}
                                        iconSize={18}
                                        amount={`${this.state.fromDigitalCurrency.format(
                                            Number(this.state.swapData?.amount)
                                        )}`}
                                    ></CurrencyBalanceIcon>
                                    {this.props.fiatCurrency !== undefined && (
                                        <CurrencyBalanceIcon
                                            fontWeight={"regular"}
                                            fontColor={colors.grey}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={this.state.fromDigitalCurrency.toFiat(
                                                this.state.fromDigitalCurrency.fromDecimals(
                                                    Number(this.state.swapData?.amount)
                                                )
                                            )}
                                        ></CurrencyBalanceIcon>
                                    )}
                                </View>
                            </Row>
                            <Row style={{ justifyContent: "space-between", paddingBottom: 10 }}>
                                <RegularText color={colors.grey} style={{ marginBottom: 15 }}>
                                    {this.state.swapData?.amountReceived
                                        ? t("received")
                                        : t("you_will_receive")}
                                </RegularText>
                                <View
                                    style={{
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end"
                                    }}
                                >
                                    <CurrencyBalanceIcon
                                        equalSign={!this.state.swapData?.amountReceived}
                                        currency={this.state.toDigitalCurrency}
                                        fontSize={16}
                                        iconSize={18}
                                        amount={`${this.state.toDigitalCurrency.format(
                                            Number(
                                                this.state.swapData?.amountReceived ||
                                                    this.state.swapData?.estimatedAmount
                                            )
                                        )}`}
                                    ></CurrencyBalanceIcon>
                                    {this.props.fiatCurrency !== undefined && (
                                        <CurrencyBalanceIcon
                                            fontWeight={"regular"}
                                            fontColor={colors.grey}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={this.state.toDigitalCurrency.toFiat(
                                                this.state.toDigitalCurrency.fromDecimals(
                                                    Number(
                                                        this.state.swapData?.amountReceived ||
                                                            this.state.swapData?.estimatedAmount
                                                    )
                                                )
                                            )}
                                        ></CurrencyBalanceIcon>
                                    )}
                                </View>
                            </Row>
                        </View>
                        <View style={styles.container}>
                            <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                {t("provider")}
                            </RegularText>
                            <Row style={{ alignContent: "center", alignItems: "center" }}>
                                <AvatarBase
                                    uri={this.state.swapData?.exchange?.image}
                                    overlayContainerStyle={{ marginRight: 5 }}
                                    size={18}
                                    alias={this.state.swapData?.exchange.name}
                                ></AvatarBase>
                                <SemiBoldText color={colors.text} style={{}}>
                                    {this.state.swapData?.exchange?.name}
                                </SemiBoldText>
                            </Row>
                        </View>

                        {!this.state.swapData?.txHash && 
                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>
                                {t("transaction_id")}
                            </RegularText>
                            <Row style={{ alignItems: "baseline" }}>
                                {this.state.copyData == this.state.swapData?.skeletonId ? (
                                    <RegularText color={colors.secondary} align="left">
                                        {t("copied")}
                                    </RegularText>
                                ) : (
                                    <Row>
                                        <RegularText
                                            color={colors.grey}
                                            align="left"
                                            selectable={true}
                                        >
                                            {trimHelper(this.state.swapData?.skeletonId, 35, true)}
                                        </RegularText>
                                        <PressableBase
                                            onPress={() =>
                                                this.onPressCopy(this.state.swapData?.skeletonId)
                                            }
                                            style={{ paddingVertical: 2 }}
                                        >
                                            <Icon
                                                size={16}
                                                name="content-copy"
                                                color={colors.grey}
                                                style={{ marginLeft: 10 }}
                                            />
                                        </PressableBase>
                                    </Row>
                                )}
                            </Row>
                        </View>
                        }      
                        {this.state.swapData?.txHash && (
                            <ExplorerComponent
                                styles={styles.container}
                                txHash={this.state.swapData.txHash}
                                explorerUrl={this.state.fromDigitalCurrency?.getExplorerUrl()}
                            />
                        )}
                    </View>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
        loading: state.global.loading,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExchangeDetailComponent = connect(mapStateToProps, mapDispatchToProps)(_ExchangeDetailComponent);

export default ExchangeDetailComponent;

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        marginTop: 5,
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.secondaryShadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});
