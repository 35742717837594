import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import ETHImplementation from "@core/currencies/blockchains/ethereum/ETHImplementation";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { Networks } from "@core/network/Networks";
import BTCImplementation from "./BTCImplementation";

export default class BitcoinCurrency extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            ...parameters,
            decimals: Number(parameters.decimals) || 8,
            implementation: BTCImplementation,
            network: parameters.testnet ? Networks.bitcoin.testnet : Networks.bitcoin.mainnet,
        });
    }

    getAddress(options?: { path?: string; format?: string }) {
        if (options && options.format == "SEGWIT") {
            options.path = options.path || this.getCurrentPath("84");
        }
        return super.getAddress(options);
    }
}
