import LottieBase from "@components/animations/Lottie";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { NavigationType } from "@custom-types/NavigationType";
import QuizService from "@games/services/QuizService";
import { Quiz, QuizGameOver, UserAnswers } from "@games/types/Quiz";
import Background from "@games/views/Background";
import Finished from "@games/views/Finished";
import Intro from "@games/views/Intro";
import Questions from "@games/views/Questions";
import i18n from "@i18n/i18n";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { ready, showPopup } from "@store/actions/global";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { cleanAndParseJSON } from "@utils/helpers/lottie/lottie.helper";
import { AnimationObject } from "lottie-react-native";
import React from "react";
import { View } from "react-native";

const { t } = i18n;

export enum GameStatus {
    INTRO = "INTRO",
    QUIZ = "QUIZ",
    FINISHED = "FINISHED",
}

export default class QuizGame {
    private quiz: Quiz;
    private sessionId: string;
    private navigation: NavigationType;
    private gameStatus: GameStatus;
    private quizGameOver: QuizGameOver | null;
    private onStatusChange: (() => void) | null = null;
    private userAnswers: UserAnswers = {};

    public constructor(parameters: { quiz: Quiz; navigation?: NavigationType }) {
        this.quiz = parameters?.quiz;
        this.navigation = parameters?.navigation || null;
        this.gameStatus = GameStatus.INTRO;
        this.quizGameOver = null;
    }

    getId() {
        return this.quiz?._id;
    }

    getTitle() {
        return this.quiz?.title || "";
    }

    getImage(type: "square" | "thumbnail") {
        if (type == "square") {
            return this.quiz?.image?.square || "";
        }
        if (type == "thumbnail") {
            return this.quiz?.image?.thumbnail || "";
        }
    }

    getCharacter() {
        try {
            if (this.quiz?.character) {
                const animation = cleanAndParseJSON(this.quiz?.character);
                return <LottieBase width={350} height={350} loop={true} source={animation} />;
            }

            if (this.quiz?.characterImage?.square) {
                return (
                    <ImageBase
                        style={{
                            width: 280,
                            height: 280,
                            borderRadius: 140,
                            marginTop: 40
                        }}
                        backgroundColor={"transparent"}
                        uri={this.quiz?.characterImage?.square}
                    />
                );
            }

            return <View></View>;
        } catch {}
    }

    getMerchant() {
        return this.quiz?.merchant;
    }

    getDescription() {
        return this.quiz?.description;
    }

    getViewGame(): JSX.Element {
        switch (this.gameStatus) {
            case GameStatus.INTRO:
                return <Intro quiz={this} />;
            case GameStatus.QUIZ:
                return <Questions quiz={this} />;
            case GameStatus.FINISHED:
                return <Finished quiz={this} navigation={this.navigation} />;
            default:
                return <View></View>;
        }
    }

    getBackground() {
        return <Background quiz={this} background={this.quiz?.background?.cover} />;
    }

    getButtonAnimation(): AnimationObject | false {
        if (this.quiz?.buttonAnimation) {
            return cleanAndParseJSON(this.quiz.buttonAnimation);
        }
        return false;
    }

    handleGameStatusChange(callback: () => void): void {
        this.onStatusChange = callback;
    }

    setGameStatus(gameStatus: GameStatus): void {
        this.gameStatus = gameStatus;
        if (this.onStatusChange) {
            this.onStatusChange();
        }
    }

    getCurrency(): Currency {
        return Wallet.getInstance().findCurrencyById(this.quiz?.currency || "");
    }

    getPrizeAmount() {
        return this.quiz?.prizeAmount || 0;
    }

    getQuestions() {
        return this.quiz?.questions || [];
    }

    setUserAnswer(questionID: string, answerID: string): void {
        this.userAnswers[questionID] = answerID;
    }

    getUserAnswers() {
        return this.userAnswers;
    }

    async startSession() {
        const data: any = await QuizService.getInstance().startQuizSession(this.getId());

        this.sessionId = data.session._id;
        this.quiz.questions = data.session.questions;

        return data;
    }

    async onQuizGameOver() {
        try {
            const response: QuizGameOver = await QuizService.getInstance().sendQuizResult(
                this.sessionId,
                this.getUserAnswers()
            );

            if (response) {
                this.quizGameOver = response;
                this.setGameStatus(GameStatus.FINISHED);
            }
        } catch (e: any) {
            console.warn(e);
            store.dispatch(ready());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: trimHelper(e?.response?.data?.message, 100) || t("game_error"),
                })
            );
            this.navigation.navigate(HomeNavigatorScreens.HomeMain.routeName);
        }
    }

    getQuizGameOver() {
        return this.quizGameOver;
    }
}
