const nunitoBold = require("./Poppins-Black.ttf")
const nunitoLight = require("./Poppins-Light.ttf")
const nunitoRegular = require("./Poppins-Regular.ttf")
const nunitoSemiBold = require("./Poppins-Bold.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
