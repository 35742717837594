import BotCard from "@base/BotCard";
import Card from "@base/Card";
import Container from "@base/Container";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import ExchangeCard from "@components/exchange/ExchangeCard";
import { Header } from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { Skeleton } from "@core/currencies/CurrencyImplementation";
import Wallet from "@core/wallet/Wallet";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import SwapType from "@custom-types/SwapType";
import i18n from "@i18n/i18n";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import SelectCurrencyComponent from "@screens/shared/SelectCurrencyComponent";
import { hideModalBottom, showModalBottom } from "@store/actions/global";
import { selectCurrency, selectExchangeFrom, selectExchangeTo } from "@store/actions/wallet";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { debounce } from "lodash";
import React, { Component } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    from: Currency;
    to: Currency;
}

interface State {
    from: {
        currency: Currency;
        amount: number;
    };
    to: {
        currency: Currency;
        amount: number;
    };
    rawSwap;
    error: string;
    loading: boolean;
    skeleton: Skeleton;
}

const { t } = i18n;

class _ExchangeScreen extends Component<Props, State> {
  activeRequest = null;
  constructor(props: Props) {
    super(props);
    this.onPressNext = this.onPressNext.bind(this);
    this.onCurrencyToChange = this.onCurrencyToChange.bind(this);
    this.state = this.initState(props);
    this.getNewSwap = debounce(this.getNewSwap, 500, {});
  }

  isValidPair(currency: Currency, pairs) {
    if (!currency) return false;
    return pairs.find(
      (p) => p?.targetCurrency?.toUpperCase() == currency.getId()?.toUpperCase()
    );
  }

  initState(props) {
    const from: Currency = props.from ? props.from : null;
    if (!from) {
      return;
    }
    const pairs = from.getPairs();

    if (!pairs || pairs.length == 0) {
      return {
        from: {
          currency: from,
          amount: 0,
        },
        to: {
          currency: from,
          amount: 0,
        },
        rawSwap: null,
        error: t("pairs_errors"),
        loading: false,
        skeleton: null,
      };
    }

    const to = this.isValidPair(props.to, pairs)
      ? props.to
      : this.getInstancedCurrency(pairs);
    store.dispatch(selectExchangeFrom({ currency: from }));
    store.dispatch(selectExchangeTo({ currency: to }));
    return {
      from: {
        currency: from,
        amount: 0,
      },
      to: {
        currency: to,
        amount: 0,
      },
      error: null,
      rawSwap: null,
      loading: false,
      skeleton: null,
    };
  }

  getInstancedCurrency(pairs) {
    let pairsIDs = [];
    pairs.forEach((p) => {
      return pairsIDs.push(p?.targetCurrency);
    });

    return Wallet.getInstance()
      .getCurrencies()
      ?.find((c) => {
        return pairsIDs.includes(c.getId());
      });
  }

  componentDidMount() {
    this.setState(this.initState(this.props));
  }

  onPress = () => {
    const from = this.state.from;
    const to = this.state.to;
    store.dispatch(selectExchangeFrom(to));
    store.dispatch(selectExchangeTo(from));
    this.setState({ from: to, to: from });
  };

  onAmountFromChange = async (amount: number) => {
    if (amount != this.state.from.amount) {
      const from = this.state.from.currency;
      const to = this.state.to.currency;
      this.setState({
        from: { currency: from, amount: amount },
      });
      this.getNewSwap(from, { to, amount });
    }
  };

  async getNewSwap(currency: Currency, params: SwapType) {
    const requestId = Symbol();
    this.activeRequest = requestId;

    if (params?.amount == 0) {
      return;
    }
    if (
      params?.amount == this.getMaxAmount() &&
      currency.getKind() == "NATIVE"
    ) {
      this.setState({
        error: t("fee_error"),
      });
    }
    if (params?.amount < this.getMinAmount() && params?.amount !== 0) {
      this.setState({
        error: `${t(
          "exchange_amount"
        )} ${this.getMinAmount()} ${currency.getSymbol()}`,
      });
      return;
    }
    try {
      this.setState({ loading: true });

      let extraParams = {};

      const pair = this.isValidPair(params.to, currency.getPairs());

      if (pair.platform == "BOLTZ_EXCHANGE") {
        const publicKey = currency.getKeys().publicKey;
        const nonce = await this.state.from.currency.getExchangeNonce(params.to);
        const preImageHash = currency.getPreImageHash(nonce);
        extraParams = {
          publicKey,
          preImageHash,
          nonce,
        };
      }

      const swap = await currency.newSwap({
        to: params?.to,
        amount: params?.amount,
        ...extraParams,
      });

      const skeleton = this.state.from.currency
        .getImplementation()
        .parseSkeleton(swap);

      if (this.activeRequest !== requestId) return;
      this.setState({
        error: null,
        loading: false,
        to: {
          currency:
            Wallet.getInstance().findCurrencyById(
              swap?.extraTransactionData?.toCurrency
            ) || params?.to,

          amount: params?.to.fromDecimals(swap?.extraTransactionData?.toAmount),
        },
        rawSwap: swap || null,
        skeleton: skeleton,
      });

      return swap;
    } catch (e: any) {
      if (this.activeRequest !== requestId) return;
      this.setState({
        loading: false,
        to: { currency: params?.to, amount: 0 },
        error:
          e?.response?.data?.message?.toString() || t("an_error_has_occurred"),
        rawSwap: null,
      });
    }
  }

  onPressNext() {
    if (!this.state.rawSwap?.extraTransactionData?.approved) {
        this.props.navigation.navigate(
          ExchangeNavigatorScreens.ApproveExchange.routeName,
          {
            approvalSkeleton: this.state.from.currency.getImplementation().parseSkeleton( this.state.rawSwap),
            rawSwap: this.state.rawSwap,
          }
        );
      return;
    }

    if (!this.state.rawSwap) {
      this.setState({ error: "Swap data missing" });
      return;
    }
    this.props.navigation.navigate(
      ExchangeNavigatorScreens.ConfirmExchange.routeName,
      {
        rawSwap: this.state.rawSwap,
      }
    );
  }

  async doApproval() {
    const from = this.state.from.currency;
    const to = this.state.to.currency;
    const amount = this.state.from.amount;
    const approvalSkeleton = await from.approveSwap({
      from,
      to
    });
    return approvalSkeleton;
  }

  onSelectFrom = () => {
    let currencies: Array<Currency> =
      Wallet.getInstance().getCurrencies() || [];

    currencies = currencies.filter((x: Currency) => {
      return x.isExchangeAvailable();
    });

    store.dispatch(
      showModalBottom({
        modalContent: (
          <SelectCurrencyComponent
            currencies={currencies}
            fiatCurrency={Wallet.getInstance().getFiatCurrency()}
            onPress={(item) => {
              const pairs = item.getPairs();

              const to: Currency = this.isValidPair(
                this.state.to.currency,
                pairs
              )
                ? this.state.to.currency
                : this.getInstancedCurrency(pairs);

              this.setState({
                from: { currency: item, amount: 0 },
                to: { currency: to, amount: 0 },
                error: null,
              });
              store.dispatch(selectCurrency(item?.getId()));
              store.dispatch(selectExchangeFrom({ currency: item }));
              store.dispatch(selectExchangeTo({ currency: to }));
              store.dispatch(hideModalBottom());
            }}
          ></SelectCurrencyComponent>
        ),
      })
    );
  };

  onSelectTo = () => {
    const pairs = this.state.from.currency.getPairs();
    let pairsCurrencies: Array<Currency> = [];
    if (pairs?.length > 0) {
      pairs.map((p) => {
        const c = Wallet.getInstance().findCurrencyById(p.targetCurrency);
        if (c) {
          pairsCurrencies.push(c);
        }
      });
    }

    store.dispatch(
      showModalBottom({
        modalContent: (
          <SelectCurrencyComponent
            currencies={pairsCurrencies}
            fiatCurrency={Wallet.getInstance().getFiatCurrency()}
            onPress={(item) => {
              this.setState({ to: { currency: item, amount: 0 } }, () => {
                this.onCurrencyToChange();
              });
              store.dispatch(selectExchangeTo({ currency: item }));
              //this.onCurrencyToChange();
              store.dispatch(hideModalBottom());
            }}
          ></SelectCurrencyComponent>
        ),
      })
    );
  };

  onCurrencyToChange() {
    this.getNewSwap(this.state.from.currency, {
      to: this.state.to.currency,
      amount: this.state.from.amount,
    });
  }

  switchAmount = (value: number) => {
    this.onAmountFromChange(value);
  };

  getMinAmount = () => {
    return 0.0000000000001;
  };

  getHalfAmount = () => {
    return this.getMaxAmount() / 2;
  };

  getMaxAmount = () => {
    const currency = this.state.from.currency;
    return currency.fromDecimals(currency.getBalance());
  };

  render() {
    return (
        <ScreenWrapper>
            <Header {...this.props} title={t("exchange")} type={HeaderType.Light} />
            <Container style={{ flex: 1 }}>
                {/* SCROLL VIEW */}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        flexGrow: 1,
                        justifyContent: "space-between",
                        paddingBottom: 25
                    }}
                >
                    <View style={styles.container}>
                        <ExchangeCard
                            currency={this.state.from.currency}
                            amount={this.state.from.amount}
                            onAmountChange={this.onAmountFromChange}
                            // onPress={this.onSelectFrom}
                            disabledOnPress={true}
                        />

                        <Row style={{ alignItems: "center", marginVertical: 15 }}>
                            <View style={styles.divisor}></View>
                            {this.state.loading && (
                                <View style={styles.divisorIcon}>
                                    <ActivityIndicator size={25} color={colors.text} />
                                </View>
                            )}
                        </Row>
                        <View
                            style={{
                                opacity: this.state.loading || this.state.error ? 0.65 : 1
                            }}
                        >
                            <ExchangeCard
                                currency={this.state.to.currency}
                                amount={this.state.to.amount}
                                onPress={this.onSelectTo}
                            />
                        </View>
                    </View>

                    <View
                        style={{
                            flex: 1,
                            width: "100%"
                        }}
                    >
                        {this.state.error ? (
                            <BotCard
                                style={{ opacity: this.state.loading ? 0.7 : 1 }}
                                title={`${t("warning")}!`}
                                align={"left"}
                                message={this.state.error}
                            ></BotCard>
                        ) : (
                            <View
                                style={{
                                    paddingTop: 20,
                                    opacity: this.state.loading ? 0.25 : 1
                                }}
                            >
                                {this.state.rawSwap?.extraTransactionData?.approved != undefined &&
                                    !this.state.rawSwap?.extraTransactionData?.approved && (
                                        <BotCard message={t("approve_tokens_message")} />
                                    )}
                                {this.state.skeleton?.exchangeData?.exchangeProvider?.name?.length >
                                    0 && (
                                    <Card>
                                        <RegularText color={colors.text}>
                                            {t("provider")}
                                        </RegularText>
                                        <Row
                                            style={{ alignContent: "center", alignItems: "center" }}
                                        >
                                            <AvatarBase
                                                uri={
                                                    this.state.skeleton?.exchangeData
                                                        ?.exchangeProvider?.image
                                                }
                                                overlayContainerStyle={{ marginRight: 5 }}
                                                size={18}
                                                alias={
                                                    this.state.skeleton?.exchangeData
                                                        ?.exchangeProvider?.name
                                                }
                                            ></AvatarBase>
                                            <SemiBoldText
                                                numberOfLines={1}
                                                color={colors.text}
                                                style={{}}
                                            >
                                                {
                                                    this.state.skeleton?.exchangeData
                                                        ?.exchangeProvider?.name
                                                }
                                            </SemiBoldText>
                                        </Row>
                                    </Card>
                                )}

                                {this.state.skeleton?.exchangeData?.exchangeFeeData?.map((item) => {
                                    const currency = Wallet.getInstance().findCurrencyById(
                                        item?.digitalCurrencyId
                                    );
                                    if (!currency) return;
                                    return (
                                        <Card>
                                            <RegularText color={colors.text} align="center">
                                                {t("service_fee")}
                                            </RegularText>
                                            <CurrencyBalanceIcon
                                                style={{ paddingBottom: 5 }}
                                                iconSize={22}
                                                fontSize={18}
                                                currency={currency}
                                                amount={item?.amount}
                                            />

                                            <CurrencyBalanceIcon
                                                style={styles.balanceIcon}
                                                iconSize={15}
                                                fontSize={12}
                                                fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                                amount={currency.toFiat(item?.amount)}
                                            />
                                        </Card>
                                    );
                                })}

                                {(
                                  this.state?.skeleton?.feeData &&
                                  this.state.rawSwap?.extraTransactionData?.approved) && 
                                  (
                                        <Card>
                                            <RegularText color={colors.text} align="center">
                                                {t("fee")}
                                            </RegularText>
                                            <CurrencyBalanceIcon
                                                style={{ paddingBottom: 5 }}
                                                iconSize={22}
                                                fontSize={18}
                                                currency={Wallet.getInstance().findCurrencyById(
                                                    this.state?.skeleton?.feeData?.digitalCurrencyId
                                                )}
                                                amount={this.state?.skeleton?.feeData?.amount}
                                            />

                                            <CurrencyBalanceIcon
                                                style={styles.balanceIcon}
                                                iconSize={15}
                                                fontSize={12}
                                                fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                                amount={Wallet.getInstance()
                                                    .findCurrencyById(
                                                        this.state?.skeleton?.feeData
                                                            ?.digitalCurrencyId
                                                    )
                                                    .toFiat(
                                                        Number(
                                                            this.state?.skeleton?.feeData?.amount
                                                        )
                                                    )}
                                            />
                                        </Card>
                                    )}
                            </View>
                        )}
                    </View>

                    <View
                        style={{
                            marginTop: 20,
                            justifyContent: "flex-end"
                        }}
                    >
                        <InlineButton
                            disabled={this.state.loading}
                            vibrate={true}
                            title={t("next")}
                            onPress={this.onPressNext}
                            style={{
                                marginHorizontal: 0,
                                opacity: this.state.loading ? 0.6 : 1
                            }}
                        />
                    </View>
                </ScrollView>
            </Container>
        </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.shadow,
        borderRadius: 20,
        padding: 20,
    },
    divisor: {
        height: 1,
        backgroundColor: colors.background,
        flex: 1,
        marginVertical: 20,
    },
    divisorIcon: {
        width: 40,
        height: 40,
        marginLeft: 10,
        backgroundColor: colors.secondary,
        borderRadius: 40,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    balanceIcon: {
        backgroundColor: colors.tertiaryShadow,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 16,
        overflow: "hidden",
    },
});

const mapStateToProps = (state) => {
    return {
        from: state.wallet.selectedExchangeFrom,
        to: state.wallet.selectedExchangeTo,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ExchangeScreen);

export default ExchangeScreen;
