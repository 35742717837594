import BoldText from "@base/BoldText";
import FlatListBase from "@base/FlatListBase";
import PressableBase from "@base/PressableBase";
import RadiusButton from "@base/RadiusButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, GameRewardActivityData, OrderModel } from "@custom-types/ActivityModel";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import * as Clipboard from "expo-clipboard";
import moment from "moment";
import React, { Component } from "react";
import { Linking, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    activity: ActivityModel;
    fiatCurrency: FiatCurrency;
}

interface State {
    activityData: GameRewardActivityData;
    currency: Currency;
    copyData: string;
}

const { t } = i18n;

export class _ActivityGameRewardModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activityData: this.props?.activity?.data,
            currency: Wallet.getInstance().findCurrencyById(this.props?.activity?.data?.digitalCurrency) || null,
            copyData: "",
        };
    }

    onPressCopy = async (data) => {
        await Clipboard.setStringAsync(data);
        this.setState({ copyData: data });
        setTimeout(() => {
            this.setState({ copyData: "" });
        }, 3000);
    };

    render() {
        return (
            <>
                {this.props.activity && this.state.currency && (
                    <View style={{ paddingBottom: 20 }}>
                        <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                            <Row style={{ alignItems: "center" }}>
                                <CurrencyIcon
                                    styles={{ marginRight: 15 }}
                                    iconSize={35}
                                    size={50}
                                    currency={this.state.currency}
                                />
                                <View>
                                    <Row style={{ alignItems: "center" }}>
                                        <BoldText fontSize={22}>
                                            {`${this.state.currency.fromDecimals(
                                                this.state.activityData?.txDetails?.amount
                                            )} ${this.state.currency.getSymbol()}`}
                                        </BoldText>
                                        <NetworkCurrencySymbol currency={this.state.currency}></NetworkCurrencySymbol>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <SemiBoldText
                                            style={{
                                                backgroundColor: colors.secondary,
                                                paddingHorizontal: 8,
                                                paddingVertical: 3,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                opacity: 0.8,
                                                marginRight: 5,
                                            }}
                                            fontSize={10}
                                            color={colors.white}
                                        >
                                            {t("received").toUpperCase()}
                                        </SemiBoldText>
                                        {this.props.activity?.createdAt !== undefined && (
                                            <RegularText fontSize={12} color={colors.grey}>
                                                {this.state.currency
                                                    .getImplementation()
                                                    .parseDate(this.props.activity?.createdAt)}
                                            </RegularText>
                                        )}
                                    </Row>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.container}>
                            <RegularText>{t("game_detail")}</RegularText>
                            <Row style={{ marginTop: 10 }}>
                                <ImageBase
                                    style={{ height: 60, width: 60, borderRadius: 10 }}
                                    uri={this.state.activityData?.game?.image?.thumbnail}
                                ></ImageBase>

                                <View style={{ paddingLeft: 10 }}>
                                    <SemiBoldText color={colors.text}>
                                        {trimHelper(this.state.activityData?.game?.title, 35, true)}
                                    </SemiBoldText>

                                    <Row style={{ alignItems: "center", alignContent: "center", paddingTop: 5 }}>
                                        <AvatarBase
                                            uri={this.state.activityData?.game?.merchant?.image?.thumbnail}
                                            alias={this.state.activityData?.game?.merchant?.name}
                                            size={22}
                                        />
                                        <RegularText fontSize={15} color={colors.grey}>
                                            {"  "}
                                            By{" "}
                                            <SemiBoldText fontSize={14} color={colors.grey}>
                                                {this.state.activityData?.game?.merchant?.name}
                                            </SemiBoldText>
                                        </RegularText>
                                    </Row>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>{t("prize")}</RegularText>
                            <Row style={{ justifyContent: "space-between", paddingBottom: 10 }}>
                                <RegularText color={colors.grey} style={{ marginBottom: 15 }}>
                                    {t("amount")}
                                </RegularText>
                                <View
                                    style={{
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <CurrencyBalanceIcon
                                        currency={this.state.currency}
                                        fontSize={16}
                                        iconSize={18}
                                        amount={`${this.state.currency.fromDecimals(
                                            this.state?.activityData?.txDetails?.amount
                                        )}`}
                                    ></CurrencyBalanceIcon>
                                    {this.props.fiatCurrency !== undefined && (
                                        <CurrencyBalanceIcon
                                            fontWeight={"regular"}
                                            fontColor={colors.grey}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={this.state.currency.toFiat(
                                                this.state.currency.fromDecimals(
                                                    this.state?.activityData?.txDetails?.amount
                                                )
                                            )}
                                        ></CurrencyBalanceIcon>
                                    )}
                                </View>
                            </Row>
                        </View>

                        {this.state.currency.getBlockchain() !== 'centralized' &&
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
                                <Row style={{ alignItems: "baseline" }}>
                                    {this.state.copyData == this.state.activityData?.txDetails?.txId ? (
                                        <RegularText color={colors.secondary} align="left">
                                            {t("copied")}
                                        </RegularText>
                                    ) : (
                                        <Row>
                                            <RegularText color={colors.grey} align="left" selectable={true}>
                                                {trimHelper(this.state.activityData?.txDetails?.txId, 35, true)}
                                            </RegularText>
                                            <PressableBase
                                                onPress={() => this.onPressCopy(this.state.activityData?.txDetails?.txId)}
                                                style={{ paddingVertical: 2 }}
                                            >
                                                <Icon
                                                    size={16}
                                                    name="content-copy"
                                                    color={colors.grey}
                                                    style={{ marginLeft: 10 }}
                                                />
                                            </PressableBase>
                                        </Row>
                                    )}
                                </Row>
                                <Row style={{ paddingTop: 5 }}>
                                    <RegularText
                                        onPress={() =>
                                            `${this.state.currency.getExplorerUrl()}/${
                                                this.state.activityData?.txDetails?.txId
                                            }`
                                        }
                                        fontSize={14}
                                        color={colors.blue}
                                    >
                                        {trimHelper(this.state.currency.getExplorerUrl(), 20)}
                                    </RegularText>
                                    <RadiusButton
                                        style={{ paddingVertical: 2, marginLeft: 5 }}
                                        color={colors.grey}
                                        fontSize={12}
                                        title={"Blockchain Explorer"}
                                        onPress={() =>
                                            Linking.openURL(
                                                `${this.state.currency.getExplorerUrl()}/${
                                                    this.state.activityData?.txDetails?.txId
                                                }`
                                            )
                                        }
                                    ></RadiusButton>
                                </Row>
                            </View>
                        }
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
});

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
        currentOperation: state.wallet.currentOperation,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityGameRewardModal = connect(mapStateToProps, mapDispatchToProps)(_ActivityGameRewardModal);

export default ActivityGameRewardModal;
