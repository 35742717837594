import BoldText from "@base/BoldText";
import Card from "@base/Card";
import Row from "@base/Row";
import RegularText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import TypeWriter from "@components/text/TypeWriter";
import ActivityService from "@core/services/ActivityService";
import { ModuleControlService } from "@core/services/ModuleControlService";
import ShareService from "@core/services/ShareService";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import GameButton from "@games/components/GameButton";
import QuizGame, { GameStatus } from "@games/core/QuizGame";
import { getScale } from "@games/helpers/scale.helpers";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";

import { connect } from "react-redux";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    quiz?: QuizGame;
    client: Client;
}

interface State {
    showDetails: boolean;
}

export class _Finished extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressReturn = this.onPressReturn.bind(this);
        this.state = {
            showDetails: false,
        };
    }

    onPressReturn() {
        this.props.quiz?.setGameStatus(GameStatus.INTRO);
        ActivityService.getInstance().getActivity();
        this.props.navigation.navigate("Home");
    }

    handleShare = async () => {
        const url = `${ModuleControlService.getInstance().getShareUrl()}?type=quiz&id=${this.props?.quiz?.getId()}`;
        ShareService.getInstance().handleShare(
            `${t("share_winner")} ${this.props?.quiz?.getTitle()} (By ${this.props?.quiz?.getMerchant()?.name})`,
            url
        );
    };

    render() {
        const scale = getScale();

        return (
            <>
                {this.props.quiz?.getQuizGameOver()?.isWon && (
                    <View
                        style={{
                            flex: 1,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <View
                                style={{ marginTop: -100, marginBottom: -60, width: "100%", height: scale * 320 + 130 }}
                            >
                                <View style={{ transform: [{ scale }] }}>
                                    <LottieBase
                                        width={320}
                                        height={320}
                                        loop={false}
                                        source={require("../assets/animations/winner.json")}
                                    />
                                </View>
                            </View>

                            <View style={{ height: 75 }}>
                                <TypeWriter
                                    align={"center"}
                                    textToType={t("game_winner", {
                                        answers: this.props.quiz?.getQuizGameOver()?.correctAnswers,
                                    })}
                                    typingSpeed={50}
                                    fontSize={20}
                                    onTypingEnd={() => this.setState({ showDetails: true })}
                                ></TypeWriter>
                            </View>

                            {this.state.showDetails && (
                                <View style={{ width: "100%" }}>
                                    <View>
                                        <Card
                                            style={{
                                                alignItems: "flex-start",
                                                width: "100%",
                                                backgroundColor: getColorOpacity(colors.grey, 0.3),
                                            }}
                                        >
                                            <View
                                                style={{
                                                    width: "100%",
                                                    borderBottomColor: colors.grey,
                                                    borderBottomWidth: 0.5,
                                                    paddingBottom: 10,
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <RegularText style={{ paddingBottom: 5 }}>{t("prize")}</RegularText>
                                                <Row style={{ alignContent: "center", alignItems: "center" }}>
                                                    <CurrencyBalanceIcon
                                                        amount={this.props.quiz?.getPrizeAmount()}
                                                        fontSize={22}
                                                        iconSize={22}
                                                        fontWeight={"bold"}
                                                        currency={this.props?.quiz?.getCurrency()}
                                                        style={{}}
                                                    />
                                                    <NetworkCurrencySymbol
                                                        styles={{ marginLeft: 4, paddingVertical: 3 }}
                                                        currency={this.props.quiz?.getCurrency()}
                                                    />
                                                </Row>
                                            </View>

                                            <View
                                                style={{
                                                    width: "100%",
                                                    alignItems: "flex-start",
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <RegularText style={{ paddingBottom: 5 }}>{t("winner")}</RegularText>

                                                <Row
                                                    style={{
                                                        width: "100%",

                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <AvatarBase
                                                        alias={this.props.client?.alias}
                                                        uri={this.props.client?.profileImagePath?.thumbnail}
                                                        size={45}
                                                    ></AvatarBase>

                                                    <View style={{ paddingLeft: 10 }}>
                                                        <BoldText align="left" color={colors.text}>
                                                            {"@"}
                                                            {trimHelper(this.props.client?.alias, 35, true)}
                                                        </BoldText>
                                                        <Row
                                                            style={{
                                                                alignItems: "center",
                                                                alignContent: "center",
                                                                paddingTop: 5,
                                                            }}
                                                        >
                                                            <CurrencyIcon
                                                                size={16}
                                                                iconSize={10}
                                                                currency={this.props.quiz?.getCurrency()}
                                                            ></CurrencyIcon>
                                                            <RegularText
                                                                fixedWidth={300}
                                                                color={colors.text}
                                                                align="left"
                                                                selectable={true}
                                                            >
                                                                {" "}
                                                                {trimHelper(
                                                                    this.props.quiz?.getCurrency().getAddress(),
                                                                    30,
                                                                    true
                                                                )}
                                                            </RegularText>
                                                        </Row>
                                                    </View>
                                                </Row>
                                            </View>
                                        </Card>
                                    </View>
                                </View>
                            )}
                        </View>
                        {this.state.showDetails && (
                              <View style={{width: "100%"}}>
                              <GameButton
                                  style={{ marginBottom: 20 }}
                                  title={t("share")}
                                  fontSize={18}
                                  onPress={this.handleShare}
                                  vibrate={true}
                                  selected={false}
                              ></GameButton>
                            <GameButton
                                style={{ marginBottom: 30 }}
                                title={t("back")}
                                fontSize={18}
                                onPress={this.onPressReturn}
                                vibrate={true}
                                animation={this.props.quiz.getButtonAnimation()}
                                selected={false}
                            ></GameButton>
                            </View>
                        )}
                    </View>
                )}

                {this.props.quiz?.getQuizGameOver()?.isWon == false && (
                    <View
                        style={{
                            flex: 1,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 25,
                        }}
                    >
                        <View>
                            <View style={{ marginTop: -50, marginBottom: 0 }}>
                                <LottieBase
                                    width={350}
                                    height={350}
                                    loop={true}
                                    source={require("../assets/animations/lose.json")}
                                />
                            </View>

                            <View style={{ height: 100 }}>
                                <TypeWriter
                                    align={"center"}
                                    textToType={t("game_loser")}
                                    typingSpeed={50}
                                    fontSize={22}
                                    onTypingEnd={() => this.setState({ showDetails: true })}
                                ></TypeWriter>
                            </View>
                        </View>
                        {this.state.showDetails && (
                          
                                <GameButton
                                    style={{ marginTop: 40 }}
                                    title={t("back")}
                                    fontSize={22}
                                    onPress={this.onPressReturn}
                                    vibrate={true}
                                    animation={this.props.quiz?.getButtonAnimation()}
                                    selected={false}
                                ></GameButton>
                            
                        )}
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const Finished = connect(mapStateToProps, mapDispatchToProps)(_Finished);

export default Finished;
