import BoldText from "@base/BoldText";
import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ActivityService from "@core/services/ActivityService";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, ActivityTypes, DigitalCurrencyExchangeActivityData } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import ExchangeDetailComponent from "@screens/wallet/exchange/ExchangeDetailComponent";
import { setActivity } from "@store/actions/activity.actions";
import { showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    fiatCurrency?: FiatCurrency;
    activity: ActivityModel;
    client: Client;
    activities: Array<ActivityModel>;
    paginator;
    onPressCallBack?: () => void;
}

interface State {
    fromDigitalCurrency: Currency;
    toDigitalCurrency: Currency;
    activityData: DigitalCurrencyExchangeActivityData;
}

const { t } = i18n;

export class _ActivityCardDigitalCurrencyExchange extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openSwap = this.openSwap.bind(this);
        this.getData = this.getData.bind(this);
        this.state = {
            fromDigitalCurrency: Wallet.getInstance().findCurrencyById(
                this.props?.activity?.data?.fromDigitalCurrency || ""
            ),
            toDigitalCurrency: Wallet.getInstance().findCurrencyById(
                this.props?.activity?.data?.toDigitalCurrency || ""
            ),
            activityData: this.props?.activity?.data,
        };
    }

    async openSwap() {
        const activity = await this.getData();
        store.dispatch(
            showModalBottom({
                modalContent: <ExchangeDetailComponent rawData={activity} onPressCallBack={this.getData} />,
            })
        );
    }

    async getData() {
        const resp = await ActivityService.getInstance().getActivityById(this.props?.activity?._id);
        const activity = resp || this.props?.activity;

        this.updateActivities(activity);
        return activity;
    }

    async updateActivities(activity: ActivityModel) {
        const digitalCurrencyActivity = this.state.fromDigitalCurrency
            .getActivity()
            ?.map((a: ActivityModel) => (a?._id == activity?._id ? activity : a));

        this.state.fromDigitalCurrency.setActivity(digitalCurrencyActivity);

        const activities = this.props?.activities?.map((a: ActivityModel) => (a?._id == activity?._id ? activity : a));
        store.dispatch(setActivity([], this.props.paginator));
        setTimeout(() => {
            store.dispatch(setActivity(activities, this.props.paginator));
        }, 150);

        this?.props?.onPressCallBack ? this.props.onPressCallBack() : null;
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                        {this.props.activity?.type == ActivityTypes.DigitalCurrencyExchange && (
                            <Card
                                onPress={this.openSwap}
                                icon={
                                    <View>
                                        <CurrencyIcon
                                            size={45}
                                            currency={this.state.fromDigitalCurrency}
                                        ></CurrencyIcon>
                                        <View style={styles.secondaryAvatar}>
                                            <CurrencyIcon
                                                size={20}
                                                currency={this.state.toDigitalCurrency}
                                            ></CurrencyIcon>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center", maxWidth: 250 }}>
                                        <RegularText numberOfLines={2} fontSize={14} style={{}}>
                                            {(() => {
                                                const { status, amount, exchange } = this.state.activityData;
                                                const currency = this.state.fromDigitalCurrency;
                                                const formattedAmount = trimHelper(
                                                    currency?.fromDecimals(Number(amount))?.toString(),
                                                    8,
                                                    true
                                                );
                                                const symbol = currency?.getSymbol();

                                                switch (status) {
                                                    case "READY_TO_CLAIM":
                                                        return (
                                                            <>
                                                                {t("you_have")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                                {" " + t("ready_to_claim").toLowerCase()}
                                                            </>
                                                        );

                                                        case "CLAIMING":
                                                        return (
                                                            <>
                                                                {t("claming")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                            </>
                                                        );

                                                    case "DONE":
                                                        return (
                                                            <>
                                                                {t("changed")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                                {" " + t("in") + " "}
                                                                <SemiBoldText
                                                                    numberOfLines={1}
                                                                    fontSize={14}
                                                                    style={{ marginBottom: 2 }}
                                                                >
                                                                    {exchange?.name}
                                                                </SemiBoldText>
                                                            </>
                                                        );

                                                    case "PENDING":
                                                    case "IN_PROGRESS":
                                                        return (
                                                            <>
                                                                {t("transaction_pending_for")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                            </>
                                                        );

                                                    case "FAILED":
                                                    case "INVALID":
                                                    case "NOT_FOUND":
                                                        return (
                                                            <>
                                                                {t("transaction_failed_for")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                            </>
                                                        );

                                                    case "EXPIRED":
                                                    case "REFUNDED":
                                                        return (
                                                            <>
                                                                {t("transaction_not_completed")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                            </>
                                                        );

                                                    default:
                                                        return (
                                                            <>
                                                                {t("status_unknown_for")}
                                                                <SemiBoldText fontSize={14}>
                                                                    <BoldText fontSize={14}>
                                                                        {" "}
                                                                        {formattedAmount}{" "}
                                                                    </BoldText>
                                                                    {symbol}
                                                                </SemiBoldText>
                                                            </>
                                                        );
                                                }
                                            })()}{" "}
                                            {t("in")}
                                            <SemiBoldText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {" "}
                                                {this.state.activityData?.exchange?.name}{" "}
                                            </SemiBoldText>
                                            <View style={{ width: 22, height: 15 }}>
                                                <View style={{ position: "absolute", top: 2, left: 4 }}>
                                                    <AvatarBase
                                                        size={14}
                                                        uri={this.state.activityData?.exchange?.image || ""}
                                                    ></AvatarBase>
                                                </View>
                                            </View>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {" ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View style={{ alignItems: "flex-end", paddingTop: 3 }}>
                                        <CurrencyBalanceIcon
                                            style={{ paddingBottom: 5 }}
                                            fontSize={10}
                                            iconSize={11}
                                            fontWeight={"regular"}
                                            amount={this.state.toDigitalCurrency?.toFiat(
                                                this.state.toDigitalCurrency?.fromDecimals(
                                                    Number(
                                                        this.state.activityData?.amountReceived ||
                                                            this.state.activityData?.estimatedAmount
                                                    )
                                                )
                                            )}
                                            fiatCurrency={this.props.fiatCurrency}
                                        />
                                        <CurrencyBalanceIcon
                                            equalSign={!this.state.activityData?.amountReceived}
                                            fontSize={14}
                                            iconSize={15}
                                            trimLength={5}
                                            amount={this.state.toDigitalCurrency?.fromDecimalsToString(
                                                Number(
                                                    this.state.activityData?.amountReceived ||
                                                        this.state.activityData?.estimatedAmount
                                                )
                                            )}
                                            currency={this.state.toDigitalCurrency}
                                        />
                                    </View>
                                }
                            ></Card>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 100,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
        activities: state.activity.activity,
        paginator: state.activity.paginator,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardDigitalCurrencyExchange = connect(
    mapStateToProps,
    mapDispatchToProps
)(_ActivityCardDigitalCurrencyExchange);

export default ActivityCardDigitalCurrencyExchange;
