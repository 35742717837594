import RegularText from "@base/RegularText";
import Row from "@base/Row";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import i18n from "@i18n/i18n";
import React, { Component } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import CurrencyIcon from "./CurrencyIcon";
import BoldText from "@base/BoldText";
import { SemiBoldText } from "@base/SemiBold";
import { trimHelper } from "@utils/helpers/chat/chat.helper";

interface Props {
    fiatCurrency?: FiatCurrency;
    currency?: Currency;
    balance?: number;
    amount?: string | number;
    style?: ViewStyle;
    iconSize?: number;
    fontSize?: number;
    fontWeight?: "regular" | "bold";
    fontColor?: string;
    trimLength?: number;
    equalSign?: boolean;
}

interface State {}

const { t } = i18n;

export default class CurrencyBalanceIcon extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.fiatCurrency && (
                    <Row
                    style={[
                        this.props.style,
                        { justifyContent: "center", alignContent: "center", alignItems: "center" },
                    ]}
                >
                        <CurrencyIcon
                            currency={this.props.fiatCurrency}
                            size={this.props.iconSize || 14}
                        ></CurrencyIcon>
                        <RegularText
                            style={{ paddingRight: 1 }}
                            color={this.props.fontColor}
                            fontSize={this.props.fontSize || 12}
                        >
                            {" "}
                            {this.props.fiatCurrency.getSign()}
                        </RegularText>
                        {this.props.balance !== undefined && (
                            <>
                                {this.props.fontWeight == "regular" ? (
                                    <RegularText
                                        color={this.props.fontColor}
                                        align="center"
                                        fontSize={this.props.fontSize || 12}
                                    >
                                        {this.props.fiatCurrency.fiatFormat(this.props.balance)}{" "}
                                    </RegularText>
                                ) : (
                                    <SemiBoldText
                                        color={this.props.fontColor}
                                        align="center"
                                        fontSize={this.props.fontSize || 12}
                                    >
                                        {this.props.fiatCurrency.fiatFormat(this.props.balance)}{" "}
                                    </SemiBoldText>
                                )}
                            </>
                        )}

                        {this.props.amount !== undefined && (
                            <>
                                {this.props.fontWeight == "regular" ? (
                                    <RegularText
                                        color={this.props.fontColor}
                                        align="center"
                                        fontSize={this.props.fontSize || 12}
                                        numberOfLines={1}
                                    >
                                        {this.props.equalSign && "≈"}{this.props.amount}
                                    </RegularText>
                                ) : (
                                    <SemiBoldText
                                        color={this.props.fontColor}
                                        align="center"
                                        fontSize={this.props.fontSize || 12}
                                        numberOfLines={1}
                                    >
                                        {this.props.amount}
                                    </SemiBoldText>
                                )}
                            </>
                        )}
                        <RegularText color={this.props.fontColor} fontSize={this.props.fontSize || 12}>
                            {" "}
                            {this.props.fiatCurrency.getSymbol()}
                        </RegularText>
                    </Row>
                )}

                {this.props.currency && (
                    <Row
                        style={[
                            this.props.style,
                            { justifyContent: "center", alignContent: "center", alignItems: "center" },
                        ]}
                    >
                        <CurrencyIcon currency={this.props.currency} size={this.props.iconSize || 14}></CurrencyIcon>
                        <RegularText color={this.props.fontColor} fontSize={this.props.fontSize || 12}>
                            {" "}
                        </RegularText>
                        {this.props.balance && (
                            <BoldText
                                color={this.props.fontColor}
                                align="center"
                                fontSize={this.props.fontSize || 12}
                                numberOfLines={1}
                            >
                                {this.props.fiatCurrency.fiatFormat(this.props.balance)}{" "}
                            </BoldText>
                        )}

                        <BoldText
                            color={this.props.fontColor}
                            align="center"
                            fontSize={this.props.fontSize || 12}
                            numberOfLines={1}
                        >
                            {this.props.equalSign && "≈"}{trimHelper(this.props.amount?.toString() || "", this.props.trimLength || 10)}
                        </BoldText>

                        <RegularText color={this.props.fontColor}                     fontSize={this.props.fontSize || 12}>
                            {" "}
                            {this.props.currency.getSymbol()}
                        </RegularText>
                    </Row>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});
