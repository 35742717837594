import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { colors } from "@styles/globalStyles";
import CircleButton from "@base/CircleButton";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import store from "@store/index";
import { setButtonActionsType } from "@store/actions/wallet";
import Wallet from "@core/wallet/Wallet";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import { WalletConnectNavigatorScreens } from "@navigation/WalletConnectNavigator";
import Card from "@base/Card";
import Row from "@base/Row";


interface Props {
    navigation: NavigationType;
    navigateTo: () => null;
    syncing?: boolean
}

interface State {}

const { t } = i18n;

export enum ButtonActionsType {
    SEND = "SEND",
    RECEIVE = "RECEIVE",
    EXCHANGE = "EXCHANGE",
    BUY = "BUY",
    WALLETCONNECT = "WALLETCONNECT",
    SELL = "SELL",
}

export default class WalletsButtons extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openSelectCurrency = this.openSelectCurrency.bind(this);
        this.navigateToWalletConnect = this.navigateToWalletConnect.bind(this);
        this.state = {};
    }

    openSelectCurrency(type: ButtonActionsType) {
        store.dispatch(setButtonActionsType(type));
        this.props.navigateTo();
    }

    navigateToWalletConnect() {
        this.props.navigation.navigate(WalletConnectNavigatorScreens.WalletConnectMain.routeName);
    }

    render() {
        return (
            <Container
                style={{
                    marginBottom: Platform.OS !== "web" ? 30 : 20,
                     opacity: this.props.syncing ? 0.5 : 1, 
                }}
            >
             
                    <Row style={styles.buttons}>
                        {!Wallet.getInstance().isTestnet() &&
                            ModuleControlService.getInstance().isServiceEnabled(Services.buyService) && (
                                <CircleButton
                                    style={styles.button}
                                    fontSize={12}
                                    iconSize={25}
                                    containerStyles={styles.buttonsContainer}
                                    title={t("buy")}
                                    icon="cart"
                                    onPress={() => this.openSelectCurrency(ButtonActionsType.BUY)}
                                    disableOnPress={this.props.syncing}
                                />
                            )}
                        {!Wallet.getInstance().isTestnet() &&
                            ModuleControlService.getInstance().isServiceEnabled(Services.sellService) && (
                                <CircleButton
                                    style={styles.button}
                                    fontSize={12}
                                    iconSize={25}
                                    containerStyles={styles.buttonsContainer}
                                    title={t("sell")}
                                    icon="dollar"
                                    onPress={() => this.openSelectCurrency(ButtonActionsType.SELL)}
                                    disableOnPress={this.props.syncing}
                                />
                            )}

                        <CircleButton
                            style={styles.button}
                            fontSize={12}
                            iconSize={26}
                            containerStyles={styles.buttonsContainer}
                            title={t("send")}
                            icon="send"
                            onPress={() => this.openSelectCurrency(ButtonActionsType.SEND)}
                            disableOnPress={this.props.syncing}
                        />

                        {ModuleControlService.getInstance().isServiceEnabled(Services.exchangeService) && (
                            <CircleButton
                                style={styles.button}
                                fontSize={12}
                                iconSize={25}
                                containerStyles={styles.buttonsContainer}
                                title={t("exchange")}
                                icon="exchange"
                                onPress={() => this.openSelectCurrency(ButtonActionsType.EXCHANGE)}
                                disableOnPress={this.props.syncing}
                            />
                        )}

                        <CircleButton
                            style={styles.button}
                            fontSize={12}
                            iconSize={26}
                            containerStyles={styles.buttonsContainer}
                            title={t("receive")}
                            icon="receive"
                            onPress={() => this.openSelectCurrency(ButtonActionsType.RECEIVE)}
                            disableOnPress={this.props.syncing}
                        />
                        {ModuleControlService.getInstance().isServiceEnabled(Services.walletConnectService) && (
                            <CircleButton
                                style={styles.button}
                                fontSize={12}
                                iconSize={26}
                                containerStyles={[styles.buttonsContainer]}
                                title={"WConnect"}
                                icon="walletconnect"
                                onPress={this.navigateToWalletConnect}
                                disableOnPress={this.props.syncing}
                            />
                        )}
                    </Row>
           
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center",
        paddingHorizontal: 0,
    },
    buttonsContainer: {
        marginVertical: 5,
        marginHorizontal: 5,
    },
    button: {
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: colors.mainButtonsBackground,
    },
});
