import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { View, StyleSheet } from "react-native";
import Currency from "@core/currencies/Currency";
import AvatarBase from "@components/avatar/AvatarBase";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import RadiusButton from "@base/RadiusButton";
import { getColorOpacity } from "@utils/helpers/global/global";
import { colors } from "@styles/globalStyles";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { hideModalBottom } from "@store/actions/global";
import { Client } from "@custom-types/Client";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import Card from "@base/Card";
import InlineButton from "@base/InlineButton";
import { Skeleton } from "@core/currencies/CurrencyImplementation";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { connect } from "react-redux";
import Wallet from "@core/wallet/Wallet";
import SemiBoldText from "@base/SemiBold";

interface Props {
    avatar?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    message?: string;
    client?: Client;
    clientMessage?: string;
    btnTitle?: string;
    selectCurrency?: boolean;
    currency?: Currency;
    amount?: string | number;
    currencies?: Array<any>;
    skeleton?: Skeleton;
    onPress?: () => void;
    fiatCurrency?: FiatCurrency;
}

interface State {}

const { t } = i18n;

export class _WalletConnectModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <AvatarBase uri={this.props.avatar || ""} alias={this.props.title || ""} size={75}></AvatarBase>
                <BoldText align="center" style={{ paddingTop: 10 }} fontSize={24}>
                    {trimHelper(this.props.title || "", 25)}
                </BoldText>

                {this.props.currencies && (
                    <Row
                        style={{
                            flexWrap: "wrap",
                            paddingTop: 25,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {this.props.currencies.map((c: Currency) => {
                            return (
                                <View
                                    key={c.getId()}
                                    style={{
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        paddingRight: 6,
                                    }}
                                >
                                    <CurrencyIcon size={30} iconSize={18} currency={c} />
                                </View>
                            );
                        })}
                    </Row>
                )}

                <RegularText align="center" fontSize={18} style={{ paddingTop: 20 }}>
                    {this.props.description || ""}
                </RegularText>

                {this.props.subtitle && (
                    <RegularText align="center" style={{ paddingTop: 20 }} fontSize={14}>
                        {this.props.subtitle}
                    </RegularText>
                )}
                <View style={{ width: "100%" }}>
                    <Card style={{ marginTop: 25, marginHorizontal: 0 }}>
                        {this.props.currency && !this.props.skeleton && (
                            <View
                                style={{
                                    width: "100%",
                                    borderBottomColor: colors.grey,
                                    borderBottomWidth: 0.5,
                                    paddingBottom: 20,
                                    alignItems: "flex-start",
                                }}
                            >
                                <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                    {t("network")}
                                </RegularText>
                                <Row style={{ width: "100%", alignContent: "center", alignItems: "center" }}>
                                    <CurrencyIcon size={45} currency={this.props.currency} />
                                    <View style={{ paddingLeft: 10 }}>
                                        <BoldText fontSize={20}>{this.props.currency?.getSymbol()}</BoldText>
                                        <RegularText fontSize={14}>{this.props.currency?.getPName()}</RegularText>
                                    </View>
                                </Row>
                            </View>
                        )}

                        {this.props.client && (
                            <View
                                style={{
                                    width: "100%",
                                    marginBottom: 20,
                                    borderBottomColor: colors.grey,
                                    borderBottomWidth: 0.5,
                                    paddingVertical: 20,
                                    alignItems: "flex-start",
                                }}
                            >
                                <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                    {t("signed_by")}
                                </RegularText>
                                <Row style={{ width: "100%" }}>
                                    <AvatarBase
                                        alias={this.props.client?.alias}
                                        uri={this.props.client?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10 }}>
                                        <BoldText color={colors.text}>
                                            {"@"}
                                            {trimHelper(this.props.client?.alias, 30, true)}
                                        </BoldText>
                                        <Row style={{ paddingTop: 5, alignItems: "center" }}>
                                            <CurrencyIcon
                                                size={16}
                                                iconSize={10}
                                                currency={this.props.currency}
                                            ></CurrencyIcon>
                                            <RegularText color={colors.text} align="left" selectable={true}>
                                                {" "}
                                                {trimHelper(this.props.currency.getAddress(), 30, true)}
                                            </RegularText>
                                        </Row>
                                    </View>
                                </Row>
                            </View>
                        )}

                        {this.props.message && (
                            <View
                                style={{
                                    width: "100%",
                                    borderBottomColor: colors.grey,
                                    borderBottomWidth: 0.5,
                                    paddingBottom: 20,
                                    alignItems: "flex-start",
                                }}
                            >
                                <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                    {t("message")}
                                </RegularText>

                                <RegularText color={colors.text} fontSize={14} style={{ marginBottom: 10 }}>
                                    {trimHelper(this.props.message, 300)}
                                </RegularText>
                            </View>
                        )}

                        {this.props.skeleton && this.props.currency && (
                            <View style={{ width: "100%" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 0.5,
                                        paddingBottom: 20,
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                        {t("amount")}
                                    </RegularText>

                                    <CurrencyBalanceIcon
                                        amount={this.props.skeleton?.amount}
                                        fontSize={16}
                                        iconSize={20}
                                        fontWeight={"bold"}
                                        currency={this.props.currency}
                                        style={{ paddingBottom: 10 }}
                                    />

                                    <View style={{ alignItems: "flex-start" }}>
                                        <CurrencyBalanceIcon
                                            style={{
                                                opacity: 0.9,
                                                backgroundColor: colors.tertiaryShadow,
                                                paddingHorizontal: 6,
                                                paddingVertical: 4,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={this.props.currency.toFiat(this.props.skeleton?.amount)}
                                        />
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: "100%",
                                        marginBottom: 20,
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 0.5,
                                        paddingVertical: 20,
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                        {t("fee")}
                                    </RegularText>
                                    <CurrencyBalanceIcon
                                        amount={this.props.skeleton.feeData?.amount}
                                        fontSize={16}
                                        iconSize={20}
                                        fontWeight={"bold"}
                                        currency={Wallet.getInstance().findCurrencyById(
                                            this.props.skeleton.feeData?.digitalCurrencyId
                                        )}
                                        style={{ paddingBottom: 10 }}
                                    />

                                    <View style={{ alignItems: "flex-start" }}>
                                        <CurrencyBalanceIcon
                                            style={{
                                                opacity: 0.9,
                                                backgroundColor: colors.tertiaryShadow,
                                                paddingHorizontal: 6,
                                                paddingVertical: 4,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={Wallet.getInstance()
                                                .findCurrencyById(this.props.skeleton.feeData?.digitalCurrencyId)
                                                ?.toFiat(Number(this.props.skeleton?.feeData?.amount))}
                                        />
                                    </View>
                                </View>

                                <View style={{ width: "100%" }}>
                                    <View
                                        style={{
                                            width: "100%",
                                            borderBottomColor: colors.grey,
                                            borderBottomWidth: 0.5,
                                            paddingBottom: 20,
                                        }}
                                    >
                                        <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                            {t("sending_to")}
                                        </RegularText>
                                        <SemiBoldText color={colors.text} style={{ marginBottom: 5 }} fixedWidth={250}>
                                            {trimHelper(this.props.skeleton?.sendingTo, 30, true)}
                                        </SemiBoldText>
                                    </View>
                                </View>
                            </View>
                        )}
                    </Card>
                </View>
                {/* BUTTON */}
                {this.props.btnTitle && (
                    <InlineButton
                        style={{ width: "100%", marginTop: 30 }}
                        title={this.props.btnTitle}
                        onPress={this.props.onPress}
                    />
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 30,
        width: "100%",
    },
});

const mapStateToProps = (state) => {
    return {
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WalletConnectModal = connect(mapStateToProps, mapDispatchToProps)(_WalletConnectModal);

export default WalletConnectModal;
