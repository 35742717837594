import FlatListBase from "@base/FlatListBase";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { connect } from "react-redux";
import QuizCard from "../cards/QuizCard";
import { Quiz } from "../types/Quiz";
import RegularText from "@base/RegularText";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    quizzes?: {
        docs: Array<Quiz>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    client: Client;
}

interface State {}

const { t } = i18n;

export class _GameSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);

        this.state = {};
    }

    async componentDidMount() {}

    renderItem = ({ item }) => {
        return <QuizCard quiz={item} {...this.props}></QuizCard>;
    };

    navigateToSection() {
        // this.props.navigation.navigate("Experience", {
        //     screen: ExperienceNavigatorScreens.SectionAsset.routeName,
        // });
    }

    async loadMore() {}

    render() {
        return (
            <>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.gamesModule) && this.props.quizzes?.docs?.length > 0 &&  (
                    <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t("games")?.toUpperCase()}
                            icon={"🎰"}
                            // counter={"Quizzes"}
                            seeMore={false}
                            {...this.props}
                        ></SectionTitle>
                        {/* <RegularText>{this.props.quizzes?.docs?.length.toString()}</RegularText> */}
                        <FlatListBase
                            data={this.props.quizzes?.docs}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={5}
                            showsHorizontalScrollIndicator={false}
                            onEndReachedThreshold={1}
                            onEndReached={this.loadMore}
                        ></FlatListBase>
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        quizzes: state.games.quizzes,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const GameSection = connect(mapStateToProps, mapDispatchToProps)(_GameSection);

export default GameSection;
