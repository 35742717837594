import PressableBase from "@base/PressableBase";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { NavigationType } from "@custom-types/NavigationType";
import QuizService from "@games/services/QuizService";
import i18n from "@i18n/i18n";
import { GamesNavigatorScreens } from "@navigation/GamesNavigator";
import { showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { Quiz } from "../types/Quiz";
import { addMilliseconds, format, getHours, getMinutes } from "date-fns";

interface Props {
    navigation: NavigationType;
    quiz: Quiz;
    onPress?: (quiz: Quiz) => void;
    onlyView?: boolean;
}

interface State {
    currency: Currency;
}

const { t } = i18n;

export class _QuizCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currency: Wallet.getInstance().findCurrencyById(this.props?.quiz?.currency) || null,
        };
    }

    convertMilliseconds(ms) {
        const hours = Math.floor(ms / (1000 * 60 * 60));
        const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
        return { hours, minutes };
    }

    async navigateToQuiz() {
        const quizGame: Quiz = (await QuizService.getInstance().getQuizAsync(this.props?.quiz?._id)) || this.props.quiz;
    
        if (quizGame?.timeToPlayAgain > 0) {
            const { hours, minutes } = this.convertMilliseconds(quizGame?.timeToPlayAgain)
            store.dispatch(
                showPopupMessage({
                    type: "WARNING",
                    title: `${quizGame?.title}`,
                    message: t("time_to_play_gain", { hh: hours, mm: minutes }),
                })
            );
            return;
        }
        this.props.navigation.navigate("Games", {
            screen: GamesNavigatorScreens.Quiz.routeName,
            params: { quiz: quizGame || this.props.quiz },
        });
    }

    render() {
        return (
            <PressableBase
                onPress={() => (this.props.onPress ? this.props.onPress(this.props.quiz) : this.navigateToQuiz())}
                disabled={this.props.onlyView && !this.props.onPress}
                style={{
                    borderRadius: settings.cardRadius,
                    margin: 5,
                    overflow: "hidden",
                    flex: 1,
                    width: 180,
                    height: 180,
                    backgroundColor: colors.shadow,
                }}
            >
                <ImageBase
                    style={{ height: 180, width: 180, resizeMode: "cover" }}
                    uri={this.props.quiz?.image?.square || ""}
                ></ImageBase>

                <View style={{ position: "absolute", top: 8, right: 10, zIndex: 999 }}>
                    <AvatarBase
                        uri={this.props.quiz?.merchant?.image?.thumbnail}
                        alias={this.props.quiz?.merchant?.name}
                        size={16}
                    />
                </View>
                <View style={{ position: "absolute", top: 8, left: 10, zIndex: 999 }}>
                    <CurrencyIcon currency={this.state.currency} size={16} />
                </View>
                <View style={{ position: "absolute", top: 8, left: 25, zIndex: 999 }}>
                    <NetworkCurrencySymbol currency={this.state.currency}></NetworkCurrencySymbol>
                </View>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const QuizCard = connect(mapStateToProps, mapDispatchToProps)(_QuizCard);

export default QuizCard;
