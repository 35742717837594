import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import ETHImplementation from "@core/currencies/blockchains/ethereum/ETHImplementation";
import { Networks } from "@core/network/Networks";

export default class EVMCurrency extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            ...parameters,
            decimals: Number(parameters.decimals),
            implementation: ETHImplementation,
            networkID: 60,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
        });
    }
}
