import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import ActivityService from "@core/services/ActivityService";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, ActivityTypes, GameRewardActivityData, OrderActivityDara } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { loading, ready, showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import ActivityOrderModal from "./ActivityOrderModal";
import LottieBase from "@components/animations/Lottie";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import ActivityGameRewardModal from "./ActivityGameRewardModal";

interface Props {
    navigation?: NavigationType;
    activity?: ActivityModel;
    fiatCurrency?: FiatCurrency;
    client?: Client;
}

interface State {
    activityData: GameRewardActivityData;
    currency: Currency;
}

const { t } = i18n;

export class _ActivityCardGameReward extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressCard = this.onPressCard.bind(this);
        this.state = {
            activityData: this.props?.activity?.data,
            currency: Wallet.getInstance().findCurrencyById(this.props?.activity?.data?.digitalCurrency) || null,
        };
    }

    async onPressCard() {
        store.dispatch(
            showModalBottom({
                modalContent: <ActivityGameRewardModal activity={this.props.activity}></ActivityGameRewardModal>,
            })
        );
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                        {this.props.activity?.type == ActivityTypes.DigitalCurrencyGameReward && (
                            <>
                                <Card
                                    onPress={this.onPressCard}
                                    icon={
                                        <View>
                                            <AvatarBase
                                                size={45}
                                                uri={this.state?.activityData?.game?.merchant?.image?.thumbnail || ""}
                                                alias={this.state?.activityData?.game?.merchant?.name || "A"}
                                            ></AvatarBase>
                                            <View style={styles.secondaryAvatar}>
                                                <AvatarBase
                                                    size={20}
                                                    uri={this.props.client?.profileImagePath?.thumbnail}
                                                    alias={this.props.client?.alias || ""}
                                                ></AvatarBase>
                                            </View>
                                        </View>
                                    }
                                    left={
                                        <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                            <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_win")}
                                                {this.state.currency && (
                                                    <SemiBoldText fontSize={14}>
                                                        {" "}
                                                        {this.state.currency.fromDecimals(
                                                            this.state.activityData?.txDetails?.amount
                                                        )}{" "}
                                                        {this.state.currency?.getSymbol()}{" "}
                                                    </SemiBoldText>
                                                    // <CurrencyBalanceIcon
                                                    //     style={{ marginTop: -3.5, paddingLeft: 5, paddingRight: 5 }}
                                                    //     fontSize={14}
                                                    //     currency={this.state.currency}
                                                    //     amount={0.001}
                                                    // ></CurrencyBalanceIcon>
                                                )}
                                                {t("play_to")} {this.state.activityData?.game?.title}
                                                <View style={{ width: 25, height: 17 }}>
                                                    <View style={{ position: "absolute", top: -4, left: 5 }}>
                                                        <LottieBase
                                                            height={24}
                                                            width={24}
                                                            // loop={true}
                                                            loop={
                                                                moment().diff(
                                                                    moment(this.props.activity?.createdAt),
                                                                    "minutes"
                                                                ) < 10
                                                            }
                                                            source={require("../../../assets/animations/game-prize.json")}
                                                        />
                                                    </View>
                                                </View>
                                                <RegularText fontSize={11} color={colors.grey}>
                                                    {"  ("}
                                                    {moment(this.props.activity?.createdAt).fromNow()}
                                                    {")"}
                                                </RegularText>
                                            </RegularText>
                                        </View>
                                    }
                                    right={
                                        <View>
                                            <ImageBase
                                                style={{ height: 50, width: 50, borderRadius: 10 }}
                                                uri={this.state.activityData?.game?.image?.thumbnail}
                                            ></ImageBase>
                                        </View>
                                    }
                                ></Card>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 100,
    },
    bubble: {
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 5,
        paddingVertical: 1,
        borderRadius: 4,
        
        // marginRight: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardGameReward = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardGameReward);

export default ActivityCardGameReward;
