import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RadiusButton from "@base/RadiusButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import DigitalCurrencyTransactionType from "@custom-types/DigitalCurrencyTransactionType";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BigNumber from "bignumber.js";
import * as Clipboard from "expo-clipboard";
import React, { Component } from "react";
import { Linking, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { ExplorerComponent } from "./ExplorerComponent";

interface Props {
    fiatCurrency?: FiatCurrency;
    currency: Currency;
    transaction: DigitalCurrencyTransactionType;
}

interface State {
    fromClient: Partial<Client>;
    toClient: Partial<Client>;
    copyData: string;
}

const { t } = i18n;

export class _TransactionDetailComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fromClient: null,
            toClient: null,
            copyData: "",
        };
    }

    componentDidMount = async () => {
        const fromClient: any = await this.getProfileByAddress(this.props.transaction?.from);
        this.setState({ fromClient: fromClient || null });
        const toClient: any = await this.getProfileByAddress(this.props.transaction?.to);
        this.setState({ toClient: toClient || null });
    };

    getProfileByAddress = async (address: string) => {
        return await ProfileService.getInstance().getProfileByAddress(this.props.currency, address || "");
    };

    onPressCopy = async (data) => {
        await Clipboard.setStringAsync(data);
        this.setState({ copyData: data });
        setTimeout(() => {
            this.setState({ copyData: "" });
        }, 3000);
    };

    render() {
        return (
            <>
                {this.props.transaction && (
                    <View style={{ paddingBottom: 30 }}>
                        <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                            <Row style={{ alignItems: "center" }}>
                                <CurrencyIcon
                                    styles={{ marginRight: 15 }}
                                    iconSize={35}
                                    size={50}
                                    currency={this.props.currency}
                                />
                                <View>
                                    <Row style={{ alignItems: "center" }}>
                                        <BoldText fontSize={22}>
                                            {`${this.props.currency.format(
                                                this.props.transaction?.value
                                            )} ${this.props.currency.getSymbol()}`}
                                        </BoldText>
                                        <NetworkCurrencySymbol currency={this.props.currency}></NetworkCurrencySymbol>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <SemiBoldText
                                            style={{
                                                backgroundColor: colors.secondary,
                                                paddingHorizontal: 8,
                                                paddingVertical: 3,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                                opacity: 0.8,
                                                marginRight: 5,
                                            }}
                                            fontSize={10}
                                            color={colors.white}
                                        >
                                            {this.props.transaction?.direction == "SENT" && t("sent").toUpperCase()}
                                            {this.props.transaction?.direction == "RECEIVED" &&
                                                t("received").toUpperCase()}
                                        </SemiBoldText>
                                        {this.props.transaction?.date !== undefined && (
                                            <RegularText fontSize={12} color={colors.grey}>
                                                {this.props.currency
                                                    .getImplementation()
                                                    .parseDate(this.props.transaction.date)}
                                            </RegularText>
                                        )}
                                    </Row>
                                </View>
                            </Row>
                        </View>
                        {this.props.transaction?.confirmations !== undefined && (
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 10 }}>{t("status")}</RegularText>
                                {this.props.transaction.confirmations >= 6 ? (
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            alignContent: "flex-start",
                                        }}
                                    >
                                        <View style={{ width: 40 }}>
                                            <LottieBase
                                                height={32}
                                                width={32}
                                                loop={true}
                                                source={require("../../../assets/animations/success.json")}
                                            />
                                        </View>

                                        <BoldText fontSize={16}>{t("confirmed")}</BoldText>
                                    </Row>
                                ) : (
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            alignContent: "flex-start",
                                        }}
                                    >
                                        <View style={{ width: 50, marginLeft: -15, marginVertical: -15 }}>
                                            <LottieBase
                                                height={64}
                                                width={64}
                                                loop={true}
                                                source={require("../../../assets/animations/pending.json")}
                                            />
                                        </View>

                                        <BoldText>
                                            {t("pending")}
                                            <RegularText color={colors.grey}>{` (${
                                                this.props.transaction.confirmations
                                            } ${t("confirmations")})`}</RegularText>
                                        </BoldText>
                                    </Row>
                                )}
                            </View>
                        )}
                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>{t("transaction_detail")}</RegularText>
                            <Row style={{ justifyContent: "space-between", paddingBottom: 10 }}>
                                <RegularText color={colors.grey} style={{ marginBottom: 15 }}>
                                    {t("amount")}
                                </RegularText>
                                <View
                                    style={{
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <CurrencyBalanceIcon
                                        currency={this.props.currency}
                                        fontSize={16}
                                        iconSize={18}
                                        amount={`${this.props.currency.format(this.props.transaction?.value)}`}
                                    ></CurrencyBalanceIcon>
                                    {this.props.fiatCurrency !== undefined && (
                                        <CurrencyBalanceIcon
                                            fontWeight={"regular"}
                                            fontColor={colors.grey}
                                            fiatCurrency={this.props.fiatCurrency}
                                            amount={this.props.currency.toFiat(
                                                this.props.currency.fromDecimals(this.props.transaction.value)
                                            )}
                                        ></CurrencyBalanceIcon>
                                    )}
                                </View>
                            </Row>
                            {/* @ts-ignore */}
                            {this.props.transaction?.fee !== undefined && this.props.transaction?.fee !== "NaN" && (
                                <Row style={{ justifyContent: "space-between" }}>
                                    <RegularText color={colors.grey}>{t("fee")}</RegularText>
                                    <View
                                        style={{
                                            justifyContent: "flex-end",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <CurrencyBalanceIcon
                                            fontSize={16}
                                            iconSize={18}
                                            amount={this.props.currency
                                                .getUnderlyingCurrency()
                                                .format(this.props.transaction?.fee)}
                                            currency={this.props.currency.getUnderlyingCurrency()}
                                        />
                                        {this.props.fiatCurrency !== undefined && (
                                            <CurrencyBalanceIcon
                                                fontColor={colors.grey}
                                                fontWeight={"regular"}
                                                fiatCurrency={this.props.fiatCurrency}
                                                amount={this.props.currency
                                                    .getUnderlyingCurrency()
                                                    .toFiat(
                                                        this.props.currency
                                                            .getUnderlyingCurrency()
                                                            .fromDecimals(this.props.transaction?.fee)
                                                    )}
                                            ></CurrencyBalanceIcon>
                                        )}
                                    </View>
                                </Row>
                            )}
                        </View>
                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>{t("origin_wallet")}</RegularText>
                            {this.state.fromClient ? (
                                <Row style={{ marginTop: 10 }}>
                                    <AvatarBase
                                        alias={this.state.fromClient?.alias}
                                        uri={this.state.fromClient?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                        <BoldText color={colors.text}>
                                            {"@"}
                                            {trimHelper(this.state.fromClient?.alias, 35, true)}
                                        </BoldText>

                                        <Row style={{ alignItems: "baseline" }}>
                                            {this.state.copyData == this.props.transaction.from ? (
                                                <RegularText color={colors.secondary} align="left">
                                                    {t("copied")}
                                                </RegularText>
                                            ) : (
                                                <Row>
                                                    <RegularText
                                                        fontSize={14}
                                                        color={colors.grey}
                                                        align="left"
                                                        selectable={true}
                                                    >
                                                        {trimHelper(this.props.transaction.from, 30, true)}
                                                    </RegularText>
                                                    <PressableBase
                                                        onPress={() => this.onPressCopy(this.props.transaction.from)}
                                                        style={{ paddingVertical: 2 }}
                                                    >
                                                        <Icon
                                                            size={16}
                                                            name="content-copy"
                                                            color={colors.grey}
                                                            style={{ marginLeft: 10 }}
                                                        />
                                                    </PressableBase>
                                                </Row>
                                            )}
                                        </Row>
                                    </View>
                                </Row>
                            ) : (
                                <RegularText fontSize={14} color={colors.grey}>
                                    {this.props.transaction.from}
                                </RegularText>
                            )}
                        </View>
                        <View style={styles.container}>
                            <RegularText style={{ marginBottom: 5 }}>{t("target_wallet")}</RegularText>
                            {this.state.toClient ? (
                                <Row style={{ marginTop: 10 }}>
                                    <AvatarBase
                                        alias={this.state.toClient?.alias}
                                        uri={this.state.toClient?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                        <BoldText color={colors.text}>
                                            {"@"}
                                            {trimHelper(this.state.toClient?.alias, 35, true)}
                                        </BoldText>
                                        <Row style={{ alignItems: "baseline" }}>
                                            {this.state.copyData == this.props.transaction.to ? (
                                                <RegularText color={colors.secondary} align="left">
                                                    {t("copied")}
                                                </RegularText>
                                            ) : (
                                                <Row>
                                                    <RegularText
                                                        fontSize={14}
                                                        color={colors.grey}
                                                        align="left"
                                                        selectable={true}
                                                    >
                                                        {trimHelper(this.props.transaction.to, 30, true)}
                                                    </RegularText>
                                                    <PressableBase
                                                        onPress={() => this.onPressCopy(this.props.transaction.to)}
                                                        style={{ paddingVertical: 2 }}
                                                    >
                                                        <Icon
                                                            size={16}
                                                            name="content-copy"
                                                            color={colors.grey}
                                                            style={{ marginLeft: 10 }}
                                                        />
                                                    </PressableBase>
                                                </Row>
                                            )}
                                        </Row>
                                    </View>
                                </Row>
                            ) : (
                                <RegularText fontSize={14} color={colors.grey}>
                                    {trimHelper(this.props.transaction.to, 30, true)}
                                </RegularText>
                            )}
                        </View>
                        {this.props.currency.getBlockchain() !== 'centralized' &&
                            <ExplorerComponent styles={styles.container} txHash={this.props.transaction?.txId} explorerUrl={this.props.currency?.getExplorerUrl()} />
                        }
                    </View>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const TransactionDetailComponent = connect(mapStateToProps, mapDispatchToProps)(_TransactionDetailComponent);

export default TransactionDetailComponent;

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        marginTop: 5,
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.secondaryShadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});
