import PressableBase from "@base/PressableBase";
import { BlurView } from "expo-blur";
import { Image, ImageStyle } from "expo-image";
import React, { Component } from "react";
import { ActivityIndicator, Dimensions, Platform, Image as RNImage, View } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer-fixed";
import { SkypeIndicator } from "react-native-indicators";

interface Props {
    uri: string;
    blurRadius?: number;
    style?: ImageStyle;
    enableImageZoom?: boolean;
    enableLoader?: boolean;
    autoSize?: boolean;
    maxAutoSizeWidth?: number;
    autoSizeWidth?: boolean;
    maxAutoSizeHight?: number;
    contentFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
    cachePolicy?: null | "disk" | "memory-disk" | "memory" | "none";
    backgroundColor?: string;
    onPress?: () => void;
    onError?: () => void;
}

interface State {
    isWrong: boolean;
    imageHight: number | string;
    imageWidth: number | string;
}

export class ImageBase extends Component<Props, State> {
    ImageRef;
    constructor(props: Props) {
        super(props);
        this.setImage = this.setImage.bind(this);
        this.setImageWidth = this.setImageWidth.bind(this);
        this.onError = this.onError.bind(this);
        this.state = {
            isWrong: this.props.uri?.length < 1 || this.props.uri == undefined,
            imageHight: this.props.maxAutoSizeHight,
            imageWidth: "100%",
        };
    }
    async componentDidMount() {
        try {
            if (this.props.autoSize) {
                RNImage.getSize(this.props.uri || "", (width, height) => this.setImage(width, height));
            }

            if (this.props.autoSizeWidth) {
                RNImage.getSize(this.props.uri || "", (width, height) => this.setImageWidth(width, height));
            }
        } catch {}
    }

    setImageWidth = (width, height) => {
        this.setState({
            imageHight: this.props.style?.height || height,
            imageWidth:
                width / (height / (typeof this.props.style?.height === "number" ? this.props.style?.height : height)),
        });
    };

    setImage = (width, height) => {
        const screenWidth =  Dimensions.get("window").width < 600 ? Dimensions.get("window").width : 600;

        if (this.props.maxAutoSizeHight && height > width) {
            this.setState({
                imageHight: this.props.maxAutoSizeHight,
                imageWidth: width / (height / this.props.maxAutoSizeHight),
            });
        } else {
            this.setState({ imageHight: height / (width / (screenWidth - 40)), imageWidth: screenWidth - 40 });
        }
    };

    onError() {
        this.setState({ isWrong: true });
        this.props?.onError ? this.props.onError() : null;
    }

    render() {
        return (
            <View
                style={[
                    this.props.enableImageZoom && {
                        flex: 1,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    },
                ]}
            >
                {this.props.enableLoader && (
                    <View
                        //@ts-ignore
                        style={{
                            position: "absolute",
                            borderRadius: this.props.style?.borderRadius || 0,
                            backgroundColor: this.props?.backgroundColor || "#D1D1D1",

                            width: this.props.autoSize || this.props.autoSizeWidth ? this.state.imageWidth : "100%",
                            height: this.props.autoSize || this.props.autoSizeWidth ? this.state.imageHight : "100%",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                        }}
                    >
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={60} color="white" />
                        ) : (
                            <SkypeIndicator size={80} animationDuration={2000} color="white" />
                        )}
                    </View>
                )}
                {this.props.enableImageZoom ? (
                    <ImageViewer
                        backgroundColor={
                            this.props.enableLoader ? "transparent" : this.props?.backgroundColor || "#D1D1D1"
                        }
                        //@ts-ignore
                        style={
                            this.props.autoSize || this.props.autoSizeWidth
                                ? {
                                      height: this.state.imageHight,
                                      width: this.state.imageWidth,
                                      overflow: "hidden",
                                      borderRadius: this.props.style.borderRadius || 0,
                                  }
                                : this.props.style
                        }
                        enableImageZoom={this.props.enableImageZoom}
                        renderIndicator={() => null}
                        onPress={this.props.onPress}
                        imageUrls={[{ url: this.props.uri }]}
                    ></ImageViewer>
                ) : (
            
                        <PressableBase
                            disabled={!this.props?.onPress}
                            disableOpacity={!this.props?.onPress}
                            style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                            onPress={this.props?.onPress || null}
                        >
                            <Image
                                transition={0}
                                cachePolicy="disk"
                                onError={this.onError}
                                source={
                                    !this.state.isWrong
                                        ? { uri: this.props.uri }
                                        : require("@assets/images/placeholder_image.png")
                                }
                                blurRadius={this.props.blurRadius || 0}
                                contentFit={this.props.contentFit || "cover"}
                                style={[
                                    this.props.style,
                                    {
                                        width: this.props.style?.width ? this.props.style.width : "100%",
                                        backgroundColor: this.props.enableLoader
                                            ? "transparent"
                                            : this.props?.backgroundColor || "#D1D1D1",
                                    },
                                    //@ts-ignore
                                    (this.props.autoSize || this.props.autoSizeWidth) && {
                                        height: this.state.imageHight,
                                        width: this.state.imageWidth,
                                    },
                                ]}
                            />
                            {this.props?.blurRadius > 0 && Platform.OS == "ios" && (
                                <BlurView
                                    intensity={this.props.blurRadius * 1.5 }
                                    style={[
                                        this.props.style,

                                        {
                                            position: "absolute",
                                            width: this.props.style?.width ? this.props.style.width : "100%",
                                        },
                                        //@ts-ignore
                                        (this.props.autoSize || this.props.autoSizeWidth) && {
                                            height: this.state.imageHight,
                                            width: this.state.imageWidth,
                                        },
                                    ]}
                                ></BlurView>
                            )}
                        </PressableBase>
                  
                )}
            </View>
        );
    }
}

export default ImageBase;
