import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, TextStyle, ViewStyle } from "react-native";

export interface Props {
    textToType?: string;
    typingSpeed?: number;
    style?: TextStyle | Array<ViewStyle>;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    color?: string;
    fixedWidth?: number;
    numberOfLines?: number;
    children?: any;
    onPress?: () => void;
    onTypingEnd?: () => void;
}

function TypeWriter(props: Props) {
    const [text, setText] = useState("");
    const [showCaret, setShowCaret] = useState(true);

    useEffect(() => {
        let index = 1;
        const message = props?.textToType + " ";
        const intervalId = setInterval(() => {
            if (index < message.length) {
                setShowCaret(true);
                setText(message.slice(0, index));
                index++;
            } else {
                setTimeout(() => {
                    setShowCaret(false);
                    if (props.onTypingEnd) {
                        props.onTypingEnd();
                    }
                }, 1000);
                clearInterval(intervalId);
            }
        }, props?.typingSpeed || 75);

        return () => {
            clearInterval(intervalId);
            setText("");
        };
    }, [props.textToType]);

    return (
        <View style={props.style}>
            <RegularText {...props}>
                {text}
                <RegularText color={colors.secondary} style={[!showCaret && { opacity: 0 }]} {...props}>
                    {" "}
                    |
                </RegularText>
            </RegularText>
        </View>
    );
}

const styles = StyleSheet.create({});

export default TypeWriter;
