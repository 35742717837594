import { PathNode } from "@core/utils/PathNode";
import DigitalCurrencyTransactionType from "@custom-types/DigitalCurrencyTransactionType";
import Currency from "./Currency";
import moment from "moment";

export interface Skeleton {
    amount: number;
    sendingFrom: string;
    sendingTo: string;
    feeData: {
        amount: string;
        digitalCurrencyId?: string;
        extraData?: {
            gas?: number;
            gasPrice?: number;
        };
    };
    exchangeData?: {
        fromAmount: number;
        toCurrency: string;
        toAmount: string;
        exchangeFeeData?: Array<{
            amount: number;
            digitalCurrencyId: string;
            provider?: string;
        }>;
        exchangeProvider?: {
            name?: string;
            image?: string;
        };
    };
}

export interface ITransaction {
    type: number;
    to: string;
    amount: any;
    from: string;
    id: string;
    confirmations: number;
    fee: any;
    date: string;
}

export default abstract class CurrencyImplementation {
    protected currency: Currency;
    constructor(currency: Currency) {
        this.currency = currency;
    }

    getCurrency() {
        return this.currency;
    }

    getFormats(): Array<string> {
        return [];
    }

    getKeys(addressNode: PathNode): { publicKey: string; privateKey: string } {
        return {
            publicKey: "",
            privateKey: "",
        };
    }

    signMessage(addressNode: PathNode, hexMessage: string) {
        return null;
    }

    signTypedData(addressNode: PathNode, JsonData: string) {
        return null;
    }

    parseTransaction(tx) {
        let transaction: DigitalCurrencyTransactionType = tx;
        transaction.confirmations = Number(tx.confirmations) || 0;
        transaction.value = Number(tx?.transferValue) || Number(tx.value) || 0;
        transaction.fee = Number(tx.fee) || 0;
        transaction.block = Number(tx.block) || 0;
        return transaction;
    }

    parseDate(date) {
        return moment(date).format("LLL");
    }

    abstract generateAddress(node: PathNode, options: { chainId?: number; owner?: boolean; format?: string }): string;
    abstract signTransaction(addressNode: any, skeleton: any): Promise<any>;
    abstract parseSkeleton(skeleton): Skeleton;
    abstract isValidAddress(address: string): boolean;
}
