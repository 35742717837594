import CurrencyImplementation, { Skeleton } from "../../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as ethUtil from "ethereumjs-util";
import HDNode from "hdkey";
import Web3 from "web3";
import { add, format } from "date-fns";
import Wallet from "@core/wallet/Wallet";
import TronWeb from "tronweb";

export default class TRXImplementation extends CurrencyImplementation {
    tronInstance: any;

    getTronInstance() {
        if (!this.tronInstance) {
            this.tronInstance = new TronWeb({
                fullHost: `https://api${this.currency.isTestnet() ? ".shasta" : ""}.trongrid.io`,
            });
        }
        return this.tronInstance;
    }

    generateAddress(addressNode: PathNode) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const tron = this.getTronInstance();
        const address = tron.address.fromPrivateKey(privateKey.toString("hex"));
        return address;
    }

    async signTransaction(addressNode: PathNode, skeleton) {
        const rawTx = skeleton?.transactionData;
        if (rawTx.extra) {
            delete rawTx.extra;
        }

        if (rawTx.approved) {
            delete rawTx.approved;
        }

        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const tron = this.getTronInstance();

        return await tron.trx.sign(rawTx, privateKey.toString("hex"));
    }

    /*
    parseSkeleton(skeleton) {
        const value =
            skeleton?.transactionData?.raw_data?.contract[0]?.parameter?.value?.amount ||
            skeleton?.extraTransactionData?.value;
        0;
        const to =
            TronWeb.address.fromHex(skeleton.transactionData?.raw_data?.contract[0]?.parameter?.value?.to_address) ||
            skeleton?.extraTransactionData?.to;
        ("");
        const fee = skeleton.feeData?.trxToBurn || 0;
        const swapAmount = skeleton.extra ? skeleton.extra.swapAmount : 0;

        const undelrycurrency = this.currency.getUnderlyingCurrency();
        return {
            amount: this.currency.fromDecimals(parseInt(value)),
            sendingTo: to,
            sendingFrom: skeleton.from,
            fee: undelrycurrency.fromDecimals(fee),
            swapAmount: swapAmount,
        };
    }*/

    parseSkeleton(skeletonData): Skeleton {
        const amount = this.currency.fromDecimals(
            skeletonData?.transactionData?.raw_data?.contract[0]?.parameter?.value?.amount ||
                skeletonData?.extraTransactionData?.value ||
                0
        );
        const sendingTo =
            TronWeb.address.fromHex(
                skeletonData.transactionData?.raw_data?.contract[0]?.parameter?.value?.to_address
            ) || skeletonData?.extraTransactionData?.to;
        ("");

        const sendingFrom = skeletonData?.from || skeletonData?.addressFrom || "";
        const underlyingCurrency = this.currency.getUnderlyingCurrency();

        const feeData = {
            amount: underlyingCurrency.fromDecimalsToString(skeletonData.feeData?.trxToBurn) ?? "0",
            digitalCurrencyId: underlyingCurrency?.getId(),
        };

        const exchangeData = {
            fromAmount: this.currency.fromDecimals(skeletonData?.extraTransactionData?.value || 0),
            toCurrency: skeletonData?.extraTransactionData?.toCurrency || "",
            toAmount: Wallet.getInstance()
                .findCurrencyById(skeletonData?.extraTransactionData?.toCurrency)
                ?.fromDecimals(skeletonData?.extraTransactionData?.toAmount || 0),
            exchangeFeeData:
                skeletonData?.exchangeFeeData?.map((s) => {
                    const currency = Wallet.getInstance().findCurrencyById(s?.digitalCurrencyId);
                    if (!currency) return;
                    return {
                        amount: currency.fromDecimals(s?.amount || 0),
                        digitalCurrencyId: s?.digitalCurrencyId || "",
                        provider: s?.provider || "",
                    };
                }) || [],

            exchangeProvider: {
                name: skeletonData?.extraTransactionData?.toolDetails?.name || "",
                image: skeletonData?.extraTransactionData?.toolDetails?.logoURI || "",
            },
        };

        const skeleton: Skeleton = {
            amount: amount,
            sendingFrom: sendingFrom,
            sendingTo: sendingTo,
            feeData: feeData,
            exchangeData: exchangeData,
        };

        return skeleton;
    }

    isValidAddress(address: string) {
        return this.getTronInstance().isAddress(address);
    }
}
