
import QuizScreen from "@games/screens/QuizScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";

const GamesNavigatorScreens = {
    Quiz: {
        routeName: "Quiz",
        title: "Quiz Game",
        component: QuizScreen,
    },
};

const GamesNavigator = NavigationInstantiator(GamesNavigatorScreens);

export { GamesNavigator, GamesNavigatorScreens };
