import React, { Component } from "react";
import {
    View,
    StyleSheet,
    Text,
    Animated,
    TouchableWithoutFeedback,
    Easing,
    EmitterSubscription,
    Keyboard,
    Platform,
} from "react-native";

import { colors, settings } from "@styles/globalStyles";
import { AntDesign } from "@expo/vector-icons";
import Container from "@base/Container";
import { Chat } from "@custom-types/Chat";
import { CustomMessageType } from "@custom-types/CustomMessageType";
import store from "@store/index";
import { setCustomMessage } from "@store/actions/chat.actions";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import Icon from "@components/icons";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import Constants from "expo-constants";
import { ModuleControlService, Modules, NFTsModules } from "@core/services/ModuleControlService";
import CircleButton from "@base/CircleButton";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { RedeemableType } from "@custom-types/ExpercienceModel";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { hideModalBottom } from "@store/actions/global";

interface Props {
    selectedChat?: Chat;
    client?: any;
    navigation?: any;
}

interface State {}

const { t } = i18n;

export class OptionsButton extends Component<Props, State> {
    private keyboardWillShowSub: EmitterSubscription;
    private keyboardWillHideSub: EmitterSubscription;

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.keyboardWillShow = this.keyboardWillShow.bind(this);
        this.keyboardWillHide = this.keyboardWillHide.bind(this);
    }

    componentDidMount() {
        this.keyboardWillShowSub = Keyboard.addListener("keyboardDidShow", this.keyboardWillShow);
        this.keyboardWillHideSub = Keyboard.addListener("keyboardDidHide", this.keyboardWillHide);
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove();
        this.keyboardWillHideSub.remove();
    }

    keyboardWillShow(e) {
        if (Platform.OS == "ios") {
        }
    }

    keyboardWillHide() {
        if (Platform.OS == "ios") {
        }
    }


    onPress = (type: CustomMessageType, redeemableType?: RedeemableType) => {
        store.dispatch(hideModalBottom());
        const customMessage: Partial<CustomMessageModel> = {
            type: type,
            client: this.props.client,
        };

        store.dispatch(setCustomMessage(customMessage));
        if (type == CustomMessageType.NFT || type == CustomMessageType.SendNFT) {
            this.props.navigation.navigate(MessagesNavigatorScreens.SelectNftActions.routeName);
            return;
        }
        if (type == CustomMessageType.SendRedeemable) {
            this.props.navigation.navigate(MessagesNavigatorScreens.SelectRedeemableActions.routeName, {
                sectionType: redeemableType,
            });
            return;
        }

        if (type == CustomMessageType.SendAsset) {
            this.props.navigation.navigate(MessagesNavigatorScreens.SelectAssetActions.routeName);
            return;
        }
        this.props.navigation.navigate(TabsNavigatorScreens.Messages.routeName, {
            screen: MessagesNavigatorScreens.SelectCurrencyActions.routeName,
        });
    };

    render() {
        return (
            <View style={styles.options}>
                <View>
                    {(ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) ||
                        ModuleControlService.getInstance().isModuleEnabled(Modules.nftsModule)) && (
                        <View>
                            <Row style={styles.sectionWraper}>
                                <View style={styles.section}>
                                    <Icon
                                        style={{ paddingRight: 7 }}
                                        color={colors.labelText}
                                        size={14}
                                        name={"share"}
                                    />
                                    <BoldText fontSize={13} color={colors.labelText}>
                                        {t("share").toUpperCase()}
                                    </BoldText>
                                </View>
                            </Row>
                        </View>
                    )}
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {/* ADDRESS */}
                        {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"qr"}
                                    onPress={() => this.onPress(CustomMessageType.CryptoAddress)}
                                    fontSize={11}
                                    title={t("address").toUpperCase()}
                                    iconSize={24}
                                />
                            </View>
                        )}

                        {/* SHARE NFT */}
                        {ModuleControlService.getInstance().isModuleEnabled(Modules.nftsModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"palette"}
                                    fontSize={11}
                                    onPress={() => this.onPress(CustomMessageType.NFT)}
                                    title={"NFTs"}
                                    iconSize={23}
                                />
                            </View>
                        )}
                    </View>
                </View>

                <View>
                    <View>
                        <Row style={styles.sectionWraper}>
                            <View style={styles.section}>
                                <Icon
                                    style={{ paddingRight: 7 }}
                                    color={colors.labelText}
                                    size={15}
                                    name={"lightning-charge-1"}
                                />
                                <BoldText fontSize={13} color={colors.labelText}>
                                    {t("send").toUpperCase()}
                                </BoldText>
                            </View>
                        </Row>
                    </View>

                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {/* SEND CRYPTO */}

                        {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"wallet"}
                                    fontSize={11}
                                    onPress={() => this.onPress(CustomMessageType.SendCrypto)}
                                    title={"CRYPTOs"}
                                    iconSize={24}
                                />
                            </View>
                        )}

                        {/* SEND NFT */}
                        {ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.transfer) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"brush"}
                                    fontSize={11}
                                    onPress={() => this.onPress(CustomMessageType.SendNFT)}
                                    title={"NFTs"}
                                    iconSize={23}
                                />
                            </View>
                        )}

                        {/* SEND TICKET */}

                        {ModuleControlService.getInstance().isModuleEnabled(Modules.experiencesModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"ticket"}
                                    fontSize={11}
                                    onPress={() =>
                                        this.onPress(CustomMessageType.SendRedeemable, RedeemableType.TICKET)
                                    }
                                    title={"TICKETs"}
                                    iconSize={23}
                                />
                            </View>
                        )}

                        {/* SEND VOUCHER */}
                        {ModuleControlService.getInstance().isModuleEnabled(Modules.experiencesModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"vocuher"}
                                    fontSize={11}
                                    onPress={() =>
                                        this.onPress(CustomMessageType.SendRedeemable, RedeemableType.VOUCHER)
                                    }
                                    title={"VOUCHERs"}
                                    iconSize={23}
                                />
                            </View>
                        )}

                        {/* SEND ASSET */}
                        {ModuleControlService.getInstance().isModuleEnabled(Modules.experiencesModule) && (
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"handbag"}
                                    fontSize={11}
                                    onPress={() => this.onPress(CustomMessageType.SendAsset)}
                                    title={"ASSETs"}
                                    iconSize={23}
                                />
                            </View>
                        )}
                    </View>
                </View>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <View>
                        <View>
                            <Row style={styles.sectionWraper}>
                                <View style={styles.section}>
                                    <Icon
                                        style={{ paddingRight: 7 }}
                                        color={colors.labelText}
                                        size={16}
                                        name={"plug"}
                                    />
                                    <BoldText fontSize={13} color={colors.labelText}>
                                        {t("req").toUpperCase()}
                                    </BoldText>
                                </View>
                            </Row>
                        </View>
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            {/* REQUES PAYMENT */}
                            <View style={styles.option}>
                                <CircleButton
                                    icon={"pay-order"}
                                    fontSize={11}
                                    onPress={() => this.onPress(CustomMessageType.PayOrder)}
                                    title={t("payment").toUpperCase()}
                                    iconSize={24}
                                />
                            </View>
                        </View>
                    </View>
                )}
            </View>
        );
    }
}

export default OptionsButton;

const styles = StyleSheet.create({
    options: {
        padding: 20,
        paddingTop: 0,
        marginTop: -20,
    },

    option: {
        width: 75,
        overflow: "hidden",
        alignItems: "center",
        marginHorizontal: 0,
        marginVertical: 5,
        justifyContent: "center",
    },

    optionIcons: {
        color: colors.white,
        overflow: "hidden",
        backgroundColor: colors.secondary,
        textAlign: "center",
        borderRadius: 28,
        height: 56,
        width: 56,
        padding: 15,
    },

    sectionWraper: { alignItems: "center", paddingLeft: 10, marginBottom: 10, paddingTop: 25 },
    section: {
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: colors.complementary,
        paddingHorizontal: 12,
        paddingRight: 14,
        paddingVertical: 5,
        borderRadius: 20,
    },

    text: {
        color: colors.text,
        fontSize: 10,
        textAlign: "center",
        marginTop: 5,
        width: 85,
    },
});
