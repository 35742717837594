import { ApiService } from "@core/services/ApiService";
import { Quiz } from "../types/Quiz";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { setQuizzes } from "@store/actions/games.actions";
import store from "@store/index";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { loading, ready } from "@store/actions/global";
import i18n from "@i18n/i18n";


const { t } = i18n;
export class QuizService {
    private static instance;

    private constructor() {}

    public static getInstance(): QuizService {
        if (!QuizService.instance) {
            QuizService.instance = new QuizService();
        }

        return QuizService.instance;
    }

    async getQuizGames() {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.gamesModule)) {
            return;
        }
        try {
            const url = `${getEnv("API_URL")}quizzes`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(
                setQuizzes(response?.data?.docs, {
                    page: response?.data?.page,
                    totalDocs: response?.data?.totalDocs,
                    totalPages: response?.data?.totalPages,
                })
            );

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getQuizAsync(id) {
        try {
            store.dispatch(loading())
            const url = `${getEnv("API_URL")}quizzes/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready())
            return response.data;
        } catch (e) {
            store.dispatch(ready())
            console.warn(e);
        }
    }

    async startQuizSession(id) {
        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}quizzes/${id}/start-session`;

            const response = await axios.post(url, {}, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(ready());

            return response?.data;
        } catch (error) {
            store.dispatch(ready());
            console.warn(error);
        }
    }


    async sendQuizResult(id, answers) {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}quizzes/${id}`;
            const body = { userAnswers: answers };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response?.data;
        
    }
}

export default QuizService;


