import Wallet from "@core/wallet/Wallet";
import CurrencyImplementation, { Skeleton } from "../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as bitcoin from "bitcoinjs-lib";
import HDNode from "hdkey";
import * as btc from '@scure/btc-signer';
import { hex } from '@scure/base';
import { decode } from 'wif';
import BIP32Factory from 'bip32';
import ecc from '@bitcoinerlab/secp256k1';
import { ECPairFactory, ECPairAPI } from 'ecpair';


const bip32 = BIP32Factory(ecc);
const ECPair: ECPairAPI = ECPairFactory(ecc);
bitcoin.initEccLib(ecc);

const BIP84 = require("bip84");
const b58 = require("bs58check");
const bitcoinNetworks = { mainnet: bitcoin.networks.bitcoin, testnet: bitcoin.networks.testnet };

const bitcoinPubTypes = {
    mainnet: { zprv: "04b2430c", zpub: "04b24746" },
    testnet: { vprv: "045f18bc", vpub: "045f1cf6" },
};

function b58Encode(pub, data) {
    let payload = b58.decode(pub),
        key = payload.slice(4);

    return b58.encode(Buffer.concat([Buffer.from(data, "hex"), key]));
}

export default class BTCImplementation extends CurrencyImplementation {
    getSegWitChild() {
        const path = this.currency.getCurrentPath("84");
        const keypath = path.split("/").slice(0, 4).join("/").replace("''", "'");
        const account = bip32
            .fromSeed(new Uint8Array(this.currency.getSeed()), this.currency.getNetwork())
            .derivePath(keypath)
            .toBase58();
        return this.currency.isTestnet()
            ? b58Encode(account, bitcoinPubTypes.testnet.vprv)
            : b58Encode(account, bitcoinPubTypes.mainnet.zprv);
    }

    generateAddress(addressNode: PathNode, options?: { format?: string }) {
        if (options && options.format == "SEGWIT") {
            const child0 = this.getSegWitChild();
            const account0 = new BIP84.fromZPrv(child0);
            return account0.getAddress(Number(this.currency.getIndex()));
        }

        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = ECPair.fromPrivateKey(privateKey, { network: this.currency.getNetwork() });
        const { address } = bitcoin.payments.p2pkh({ pubkey: Buffer.from(keyPair.publicKey), network: this.currency.getNetwork() });
        return address;
    }

    getKeys(addressNode: PathNode) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = ECPair.fromPrivateKey(privateKey, { network: this.currency.getNetwork() });
        const { pubkey } = bitcoin.payments.p2pkh({ pubkey: Buffer.from(keyPair.publicKey), network: this.currency.getNetwork() });
        return { publicKey: pubkey.toString("hex"), privateKey: privateKey.toString("hex") };
    }


    async signTransaction(addressNode: PathNode, skeleton) {
        const networkType = this.currency.isTestnet()? 'testnet': 'mainnet';

        const rawTx = skeleton?.transactionData;

        const addressFormats = {
            mainnet: {
                legacy: "1",
                segwitP2SH: "3",
                segwitNative: "bc1q",
                taproot: "bc1p",
            },
            testnet: {
                legacy: ["m", "n"],
                segwitP2SH: "2",
                segwitNative: "tb1q",
                taproot: "tb1p",
            },
        };

        const network = bitcoinNetworks[networkType];
        const formats = addressFormats[networkType];

        // Derivar claves públicas y privadas
        const legacyKeys = this.getKeys(addressNode);
        const segwitPrvKey = this.getSegWitChild();
        const account0 = new BIP84.fromZPrv(segwitPrvKey);
        const segwitPubKey = hexToUint8Array(account0.getPublicKey(Number(this.currency.getIndex())));
        const legacyPubKey = hexToUint8Array(legacyKeys.publicKey);
        const decoded = decode(account0.getPrivateKey(Number(this.currency.getIndex())));
        const segwitPrivateKey = Uint8Array.from(decoded.privateKey);
        const legacyPrivateKey = Uint8Array.from(Buffer.from(legacyKeys.privateKey, 'hex'));

        const tx = new btc.Transaction();

        // Agregar inputs
        for (const input of rawTx.inputs) {
            const address = input.address || "";
            const inputConfig: any = {
                txid: input.txid,
                index: input.vout,
            };

            if (address.startsWith(formats.legacy)) {
                // P2PKH (Legacy)
                const rawTxHex = input.rawTxHex;
                if (!rawTxHex) throw new Error("Raw transaction hex missing for legacy input.");
                const nonWitnessUtxo = hex.decode(rawTxHex);
                inputConfig.nonWitnessUtxo = nonWitnessUtxo;
            } else if (address.startsWith(formats.segwitP2SH)) {
                // P2SH-P2WPKH
                const witnessUtxo = {
                    amount: BigInt(input.value),
                    script: btc.p2wpkh(segwitPubKey, network ).script,
                };
                const redeemScript = btc.p2sh(btc.p2wpkh(segwitPubKey,  network )).redeemScript;
                inputConfig.witnessUtxo = witnessUtxo;
                inputConfig.redeemScript = redeemScript;
            } else if (address.startsWith(formats.segwitNative)) {
                // Native SegWit (P2WPKH)
                inputConfig.witnessUtxo = {
                    amount: BigInt(input.value),
                    script: btc.p2wpkh(segwitPubKey, network).script,
                };
            } else if (address.startsWith(formats.taproot)) {
                // Taproot (P2TR)
                const taprootScript = btc.p2tr(segwitPubKey, null, network).script;
                inputConfig.witnessUtxo = {
                    amount: BigInt(input.value),
                    script: taprootScript,
                };
            } else {
                throw new Error(`Unsupported address format: ${address}`);
            }
            try {

            tx.addInput(inputConfig);
            }catch(e){
                console.log(e);
            }
        }

        // Agregar outputs
        for (const output of rawTx.outputs) {
            try { 
            tx.addOutputAddress(output.address, BigInt(output.value), network);
            }catch(e) {
                console.log(e);
            }
        }

        // Firmar inputs
        try {
            for (let i = 0; i < rawTx.inputs.length; i++) {
                const address = rawTx.inputs[i].address || "";
                if (formats.legacy.includes(address[0])) {
                    tx.signIdx(legacyPrivateKey, i); // Firmar Legacy
                } else if (
                    address.startsWith(formats.segwitP2SH) ||
                    address.startsWith(formats.segwitNative)
                ) {
                    tx.signIdx(segwitPrivateKey, i); // Firmar SegWit
                } else if (address.startsWith(formats.taproot)) {
                    tx.signIdx(segwitPrivateKey, i, [btc.SigHash.DEFAULT]); // Firmar Taproot
                }
            }

            tx.finalize();
            return tx.hex;
        } catch (e) {
            console.error("Error signing transaction:", e);
            throw e;
        }
    }


    parseSkeleton(skeletonData): Skeleton {
        /*const value = this.currency.fromDecimals(skeleton.transactionData?.outputs[0]?.value) || 0;
        const from = skeleton.transactionData?.inputs[0]?.address || "";
        const to = skeleton.transactionData?.outputs[0]?.address || "";
        const fee = this.currency.fromDecimals(skeleton.feeData?.fee) || 0;
        const swapAmount = skeleton.extra ? skeleton.extra.swapAmount : 0;

        return {
            amount: value,
            sendingTo: to,
            sendingFrom: from,
            fee: fee,
            swapAmount: swapAmount,
        };*/
        
        const amount = this.currency.fromDecimals(
            skeletonData.transactionData?.outputs[0]?.value
        );
        const sendingFrom = skeletonData?.transactionData?.inputs[0]?.address ||  "";
        const sendingTo = skeletonData.transactionData?.outputs[0]?.address || "";
        const underlyingCurrency = this.currency.getUnderlyingCurrency();

        const feeData = {
            amount: underlyingCurrency.fromDecimalsToString(skeletonData.feeData?.amount) ?? "0",
            digitalCurrencyId: underlyingCurrency?.getId(),
        };

        const exchangeData = {
            fromAmount: this.currency.fromDecimals(skeletonData?.extraTransactionData?.value || 0),
            toCurrency: skeletonData?.extraTransactionData?.toCurrency || '',
            toAmount: Wallet.getInstance().findCurrencyById(skeletonData?.extraTransactionData?.toCurrency)?.fromDecimals(skeletonData?.extraTransactionData?.toAmount || 0),
            exchangeFeeData:
                skeletonData?.exchangeFeeData?.map((s) => {
                    const currency = Wallet.getInstance().findCurrencyById(s?.digitalCurrencyId);
                    if (!currency) return
                    return {
                        amount: currency.fromDecimals(s?.amount || 0),
                        digitalCurrencyId: s?.digitalCurrencyId || "",
                        provider: s?.provider || "",
                    };
                }) || [],

            exchangeProvider: {
                name: skeletonData?.extraTransactionData?.toolDetails?.name || "",
                image: skeletonData?.extraTransactionData?.toolDetails?.logoURI || "",
            },
        };



        const skeleton: Skeleton = {
            amount: amount,
            sendingFrom: sendingFrom,
            sendingTo: sendingTo,
            feeData: feeData,
            exchangeData: exchangeData,
        };

        return skeleton;
    }
 
    isValidAddress(address: string): boolean {
        try {
            // Valida la dirección usando la red principal (mainnet)
            bitcoin.address.toOutputScript(address, this.currency.isTestnet() ? bitcoin.networks.testnet : bitcoin.networks.bitcoin);
            return true;
        } catch (e) {
            // Si ocurre un error, la dirección no es válida
            console.error('Invalid address:', e);
            return false;
        }
    }  

    getFormats() {
        return ["SEGWIT", "LEGACY"];
    }
}


function hexToUint8Array(hex: string): Uint8Array {
    if (hex.length % 2 !== 0) {
        throw new Error("Invalid hex string");
    }
    const uint8Array = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
        uint8Array[i / 2] = parseInt(hex.substr(i, 2), 16);
    }
    return uint8Array;
}