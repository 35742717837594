import { AnimationObject } from "lottie-react-native";

export const cleanAndParseJSON = (input: string): AnimationObject | null => {
    try {
        if (input.startsWith("'") && input.endsWith("'")) {
            input = input.slice(1, -1);
        }
        return JSON.parse(input);
    } catch (error) {
        console.error("Error al parsear JSON:", error);
        return null; 
    }
};
