import ImageBase from "@components/image/ImageBase";
import QuizGame from "@games/core/QuizGame";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { Image } from "expo-image";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import { Animated, Dimensions, Platform, View } from "react-native";

const { t } = i18n;

interface Props {
    quiz?: QuizGame;
    background: string;
}

interface State {
    animatedValue: Animated.Value;
}

export default class Background extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            animatedValue: new Animated.Value(0),
        };
    }

    componentDidMount() {
        this.startAnimation();
    }

    startAnimation = () => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(this.state.animatedValue, {
                    toValue: 1,
                    duration: 15000,
                    useNativeDriver: true,
                }),
                Animated.timing(this.state.animatedValue, {
                    toValue: 0,
                    duration: 15000,
                    useNativeDriver: true,
                }),
            ])
        ).start();
    };

    render() {
        const scale = this.state.animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [1, 1.2],
        });

        return (
            <View style={[{ position: "absolute" }, Platform.OS == "web" && {overflow: "hidden", marginHorizontal: -20}]}>
                 <LinearGradient
                    style={{
                        position: "absolute",
                        width: Platform.OS == "web" ? "120%" : "100%",
                        height: "100%",
                        zIndex: 0,
                    }}
                    colors={[colors.gradientFrom, "transparent", colors.gradientTo]}
                ></LinearGradient>
                <Animated.View style={{ transform: [{ scale }], zIndex: -1,  opacity:  Platform.OS == "ios" ? 0.8 : 1, }}>
                    <ImageBase
                        style={{zIndex: 1}}
                        uri={this.props.background}
                        autoSizeWidth={true}
                        blurRadius={3}
                    ></ImageBase>
                </Animated.View>
               
            </View>
        );
    }
}
