import Currency from "@core/currencies/Currency";
import { Client } from "@custom-types/Client";
import { colors } from "@styles/globalStyles";

export function getPercentagePriceVariationDaily(data: any) {
    try {
        if (data.length > 1) {
            const percent: number = ((data[data.length - 1].price - data[0].price) / data[0].price) * 100;
            if (!isNaN(percent) && isFinite(percent)) {
                return `${percent > 0 ? "+" : ""}${percent.toFixed(2)}%`;
            }
        }
    } catch (e) {}
}

export function getPercentagePriceVariationColor(data: any) {
    if (data.length > 1) {
        const percent: number = ((data[data.length - 1].price - data[0].price) / data[0].price) * 100;
        if (!isNaN(percent)) {
            var number = Math.round(percent * 100) / 100;
            var color = colors.grey;

            number > 0.02 ? (color = colors.green) : colors.grey;
            number < -0.02 ? (color = colors.red) : colors.grey;
            return color;
        }
    }
}

export function getAddressByCurrency(client: Client, currency: Currency) {
    let addresses = client.addressesV2;
    
    if(currency.isTestnet())  {
        addresses = client.testnetAddressesV2;
    } 
    
    if (!addresses) {
        return;
    }

    if (currency.getBlockchain() == "centralized" || currency.getType().toLowerCase() == "evml2") {
        return addresses["evm"];
    }

    return addresses[currency.getType().toLowerCase()];
}
