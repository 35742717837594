import BotCard from "@base/BotCard";
import Card from "@base/Card";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import AvatarBase from "@components/avatar/AvatarBase";
import SearchBase from "@components/search/SearchBase";
import i18n from "@i18n/i18n";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { Client } from "@custom-types/Client";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { Header } from "@components/header/Header";
import Currency from "@core/currencies/Currency";
import { getAddressByCurrency } from "@utils/helpers/currency/currency.helper";
import { Route } from "@react-navigation/native";
import { NavigationType } from "@custom-types/NavigationType";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    onFind: (address) => void;
    route: Route<string, { currency: Currency; amount?: number }>;
    client: Client;
}

interface State {
    search: string;
    syncing: boolean;
    users: Array<Client>;
}

const { t } = i18n;

export class _FindClientScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onPress = this.onPress.bind(this);
        this.debouncedSearch = debounce(this.debouncedSearch, 500);
        this.state = { syncing: false, users: [], search: "" };
    }

    renderItemUser = ({ item }) => {
        return (
            <Card onPress={() => this.onPress(item)} style={{ flexDirection: "row" }}>
                <AvatarBase
                    uri={item?.profileImagePath ? item?.profileImagePath.thumbnail : ""}
                    alias={item?.alias}
                    size={35}
                />

                <RegularText style={{ textAlignVertical: "center", paddingLeft: 10 }}>
                    @{trimHelper(item?.alias, 18)}
                </RegularText>
            </Card>
        );
    };

    onSearch = async (search) => {
        this.setState({ search: search, syncing: true }, () => {
            this.debouncedSearch();
        });
    };

    async debouncedSearch() {
        if (this.state.search?.length > 1) {
            const resp = await SocialNetworkService.getInstance().searchUser(this.state.search, 1);
            if (resp) {
                let users = [];
                users = resp.docs?.filter((u: Client) => {
                    return u._id !== this.props.client?._id;
                });
                this.setState({ users: users, syncing: false });
            }
        }
    }

    async onPress(client: Client) {
        const address = getAddressByCurrency(client, this.props.route.params?.currency);

        this.props.navigation.navigate(SendNavigatorScreens.SendMain.routeName, {
            address: address,
            amount: this.props.route.params?.amount || 0,
            selectedCurrency: this.props.route.params?.currency.getId(),
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header navigation={undefined} title={t("send")} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <BotCard title={t("hi")} message={t("transfer_bot")}></BotCard>
                    <SearchBase
                        searching={this.state.syncing}
                        value={this.state.search}
                        onSearch={(value) => this.onSearch(value)}
                    />
                    <FlatList
                        data={this.state.users}
                        renderItem={this.renderItemUser}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingVertical: 20 }}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        refreshControl={<RefreshControl tintColor={colors.text} refreshing={false} />}
                        onEndReachedThreshold={1}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const FindClientScreen = connect(mapStateToProps, mapDispatchToProps)(_FindClientScreen);

export default FindClientScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,

        overflow: "hidden",
    },
});
