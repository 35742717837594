import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { ActivityIndicator, Platform, RefreshControl, ScrollView, TextStyle, View, ViewStyle } from "react-native";
import { WebView } from "react-native-webview";
import { SkypeIndicator } from "react-native-indicators";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import { isWalletConnect } from "@screens/scanner/implementations/WalletConnectImplementation";

interface Props {
    source: string;
    style?: ViewStyle;
    containerStyle?: ViewStyle;
    injectedJavaScript?: string;
    onLoadJavascript?: string;
    onMessage?: (payload) => void;
    onNavigationStateChange?: (url) => boolean;
}

interface State {
    loading: boolean;
    enabledReload: boolean;
}

export class WebViewBase extends Component<Props, State> {
    WebViewRef: any;
    intervalID: any;
    constructor(props: Props) {
        super(props);
        this.sendPostMessage = this.sendPostMessage.bind(this);
        this.onLoading = this.onLoading.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onHandleNavigationStateChange = this.onHandleNavigationStateChange.bind(this);
        this.state = {
            loading: true,
            enabledReload: true,
        };
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    async sendPostMessage(message: string) {
        // this.WebViewRef.injectJavaScript(injectWindowPostMessage(message));
    }

    async reloadWebView() {
        this.WebViewRef.reload();
    }

    async injectJS(javascript: string) {
        this.WebViewRef?.injectJavaScript(javascript);
    }

    async onLoading() {
        if (this.onLoading) {
            this.setState({ loading: false });
            setTimeout(() => {
                this.props?.onLoadJavascript ? this.WebViewRef?.injectJavaScript(this.props.onLoadJavascript) : null;
            }, 500);
        }
    }
    handleScroll(event) {
        const scroll = event?.nativeEvent?.contentOffset?.y || 0;
        if (scroll > 25) {
            // Desactiva el RefreshControl si se está desplazando
            this.setState({ enabledReload: false });
            return;
        }
        // Reactiva el RefreshControl si no hay desplazamiento
        this.setState({ enabledReload: true });
    }

    onWebViewTouchStart = () => {
        // Desactiva el RefreshControl cuando interactúa con el WebView
        this.setState({ enabledReload: false });
    };

    onWebViewTouchEnd = () => {
        // Reactiva el RefreshControl después de la interacción con el WebView
        this.setState({ enabledReload: true });
    };

    onHandleNavigationStateChange = (newNavState) => {
        const url = newNavState?.url;
        if (this.props.onNavigationStateChange) {
            return this.props.onNavigationStateChange(url);
        }

        const scheme = Constants.expoConfig?.slug;

        if (url.startsWith(scheme) === true) {
            const { queryParams } = Linking.parse(url);
            isWalletConnect(queryParams?.uri);
            return false;
        }

        if (url.startsWith("http") === false) {
            Linking.openURL(url).catch(() => {});
            return false;
        }

        return true;
    };

    render() {
        return (
            <ScrollView
                style={{ position: "relative" }}
                contentContainerStyle={{ flex: 1 }}
                refreshControl={
                    <RefreshControl
                        tintColor={colors.text}
                        onRefresh={() => this.WebViewRef.reload()}
                        refreshing={false}
                        enabled={this.state.enabledReload}
                    />
                }
            >
                {Platform.OS == "web" ? (
                    <iframe
                        ref={(WEBVIEW_REF) => {
                            this.WebViewRef = WEBVIEW_REF;
                        }}
                        src={this.props.source}
                        style={{ border: "none" }}
                        height={"100%"}
                        width={"100%"}
                        onLoad={() => this.setState({ loading: false })}
                    />
                ) : (
                    <WebView
                        ref={(WEBVIEW_REF) => {
                            this.WebViewRef = WEBVIEW_REF;
                        }}
                        containerStyle={this.props?.containerStyle}
                        style={{ backgroundColor: "transparent", ...this.props?.style }}
                        source={{ uri: this.props?.source ?? "" }}
                        originWhitelist={["*"]}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        injectedJavaScript={this.props?.injectedJavaScript ?? ""}
                        allowFileAccess={true}
                        onMessage={(payload) => this.props.onMessage(payload)}
                        onLoadEnd={this.onLoading}
                        onScroll={this.handleScroll}
                        onTouchStart={this.onWebViewTouchStart}
                        onTouchEnd={this.onWebViewTouchEnd}
                        onShouldStartLoadWithRequest={this.onHandleNavigationStateChange}
                    />
                )}

                {this.state.loading && (
                    <View
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                        }}
                    >
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={60} color="white" />
                        ) : (
                            <SkypeIndicator size={80} animationDuration={2000} color="white" />
                        )}
                    </View>
                )}
            </ScrollView>
        );
    }
}

export default WebViewBase;
