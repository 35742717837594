import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import TypeWriter from "@components/text/TypeWriter";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import GameButton from "@games/components/GameButton";
import QuizGame, { GameStatus } from "@games/core/QuizGame";
import { getScale } from "@games/helpers/scale.helpers";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

const { t } = i18n;

interface Props {
    quiz?: QuizGame;
    fiatCurrency?: FiatCurrency;
}

interface State {
    showButton: boolean;
}

export class _Intro extends Component<Props, State> {
    private _animatedIsActive;
    constructor(props: Props) {
        super(props);
        this._animatedIsActive = new Animated.Value(0);
        this.onPress = this.onPress.bind(this);
        this.state = {
            showButton: false,
        };
    }

    async onPress() {
        //@TODO: Revisar esto, si no puede comenzar debería tirarle un error, puede ser porque el server
        //se quedó sin saldo, o por que justo lo apagaron, o porque no esta completado el tiempo para poder jugar
        //Y lo mejor sería que las preguntas queden asociadas a la sessión.

        const session = await this.props?.quiz?.startSession();
        if (session) {
            this.props?.quiz?.setGameStatus(GameStatus.QUIZ);
        } else {
            //HAcer una pantalla que diga que no puede jugar
        }
    }

    

    render() {
        const scale = getScale();

        return (
            <View style={{ flex: 1, alignContent: "center", alignItems: "center" }}>
                <View style={{ position: "absolute", top: 0, left: 0 }}>
                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                        <CurrencyBalanceIcon
                            style={{
                                backgroundColor: colors.tertiaryShadow,
                                paddingHorizontal: 6,
                                paddingVertical: 4,
                                borderRadius: 16,
                                overflow: "hidden",
                            }}
                            currency={this.props.quiz?.getCurrency()}
                            amount={this.props.quiz?.getPrizeAmount()}
                            fontWeight={"regular"}
                        />

                        <CurrencyBalanceIcon
                            style={{
                                backgroundColor: colors.tertiaryShadow,
                                paddingHorizontal: 6,
                                paddingVertical: 5,
                                borderRadius: 16,
                                overflow: "hidden",
                                marginLeft: 4,
                            }}
                            fontSize={10}
                            iconSize={11}
                            fontWeight={"regular"}
                            amount={this.props.quiz?.getCurrency()?.toFiat(this.props.quiz?.getPrizeAmount())}
                            fiatCurrency={this.props.fiatCurrency}
                        />
                        <NetworkCurrencySymbol
                            styles={{ marginLeft: 4, paddingVertical: 3 }}
                            currency={this.props.quiz?.getCurrency()}
                        />
                    </Row>

                    {this.props.quiz?.getMerchant()?.name && (
                        <Row style={{ alignItems: "center", alignContent: "center", paddingTop: 10 }}>
                            <AvatarBase
                                uri={this.props.quiz?.getMerchant()?.image?.thumbnail}
                                alias={this.props.quiz?.getMerchant()?.name}
                                size={24}
                            />
                            <RegularText fontSize={15}>
                                {"  "}
                                By <SemiBoldText fontSize={14}>{this.props.quiz?.getMerchant()?.name}</SemiBoldText>
                            </RegularText>
                        </Row>
                    )}
                </View>
                <View style={{ height: (scale*250 + 125), marginTop: -35 }}>
                    <View style={{transform: [{ scale }]}}>
                        {this.props.quiz?.getCharacter()}
                    </View>
                </View>
                <View>
                    <TypeWriter
                        align={"center"}
                        typingSpeed={40}
                        fontSize={18}
                        textToType={this.props.quiz?.getDescription()}
                        onTypingEnd={() => this.setState({ showButton: true })}
                    />
                </View>

                {this.state.showButton && (
                    <View
                        style={{
                            position: "absolute",
                            bottom: 25,
                            width: "100%",
                        }}
                    >
                        <GameButton
                            title={t("lets_get_started")}
                            fontSize={18}
                            onPress={this.onPress}
                            vibrate={true}
                            animation={this.props.quiz.getButtonAnimation()}
                            selected={true}
                        ></GameButton>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const Intro = connect(mapStateToProps, mapDispatchToProps)(_Intro);

export default Intro;
