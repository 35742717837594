import { WalletNavigator } from "./WalletNavigator";
import { MessagesNavigator } from "./MessagesNavigator";
import { SocialNetworkNavigator } from "./SocialNetworkNavigator";
import { DappsNavigator } from "./DappsNavigator";
import { NTFsNavigator } from "./NFTsNavigator";
import TabInstantiator from "./helpers/TabInstantiator";
import { CustomTabsNavigator } from "@custom/CustomTabsNavigator";
import { ExperiencesNavigator } from "./ExperiencesNavigator";
import { SettingsNavigator } from "./SettingsNavigator";
import { NotificationsNavigator } from "./NotificationsNavigator";

import { HomeNavigator } from "./HomeNavigator";
import { AccountsNavigator } from "./AccountsNavigator";
import XoConnectScreen from "@screens/xoconnect/XoConnectScreen";
import { GamesNavigator } from "./GamesNavigator";

const TabsNavigatorScreens = {
    Home: {
        routeName: "Home",
        title: "Home",
        component: HomeNavigator,
    },

    Wallets: {
        routeName: "Wallets",
        title: "Wallets",
        component: WalletNavigator,
    },

    SocialNetwork: {
        routeName: "SocialNetwork",
        title: "Social Network",
        component: SocialNetworkNavigator,
    },
    Dapps: {
        routeName: "Dapps",
        title: "Dapps",
        component: DappsNavigator,
    },
    Settings: {
        routeName: "Settings",
        title: "Settings",
        component: SettingsNavigator,
    },

    Accounts: {
        routeName: "Accounts",
        title: "Accounts",
        component: AccountsNavigator,
    },

    Notifications: {
        routeName: "Notifications",
        title: "Notifications",
        component: NotificationsNavigator,
    },
    Messages: {
        routeName: "Messages",
        title: "Messages",
        component: MessagesNavigator,
    },
    NFTs: {
        routeName: "NFTs",
        title: "NFTs",
        component: NTFsNavigator,
    },
    Experiences: {
        routeName: "Experiences",
        title: "Experiences",
        component: ExperiencesNavigator,
    },
    XoConnect: {
        routeName: "XoConnect",
        title: "XoConnect",
        component: XoConnectScreen,
    },
    Games: {
        routeName: "Games",
        title: "Games",
        component: GamesNavigator,
    },

    ...CustomTabsNavigator,
};

const TabsNavigator = TabInstantiator(TabsNavigatorScreens);

export { TabsNavigator, TabsNavigatorScreens };
