import { generateMnemonic, mnemonicToSeedSync } from "bip39";
import Currency, { IConstructorCurrency, CurrencyStore } from "@core/currencies/Currency";
import { onChange, setCurrencies, setFiatCurrency } from "@store/actions/wallet";
import store from "@store/index";

import { FiatCurrency } from "@core/fiat/FiatCurrency";
import DataService from "@core/services/DataService";
import WalletSecureStorage from "@utils/storage/storages/WalletSecureStorage";
import WalletAsyncStorage from "@utils/storage/storages/WalletAsyncStorage";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import BitcoinCurrency from "@core/currencies/blockchains/btc/BitcoinCurrency";
import BTC from "@core/currencies/btc/BTC";
import EVMCurrency from "@core/currencies/blockchains/ethereum/EVMCurrency";
import { colors } from "@styles/globalStyles";
import CentralizedCurrency from "@core/currencies/blockchains/centralized/CentralizedCurrency";
import TronCurrency from "@core/currencies/blockchains/tron/TronCurrency";
import { DigitalCurrenciesService } from "@core/services/DigitalCurrenciesService";
const HDKey = require("hdkey");

interface WalletSecureStorage {
    mnemonic: string;
    seedHistory?: Array<string>;
    seed: string;
    mainAddress: string;
}

interface WalletAsyncStorage {
    fiatCurrency: FiatCurrency;
    currencies: Array<Partial<CurrencyStore>>;
    testnet: boolean;
}

interface SeedHistorySecureStorage {
    mnemonic: string;
    clientId: string;
    isWritten: boolean;
}

interface WalletState {
    secureStorage: WalletSecureStorage;
    asyncStorage: WalletAsyncStorage;
}

export default class Wallet {
    public mnemonic: string;
    public mainAddress: string;
    private seed: Buffer;
    public currencies: Array<Currency> = [];
    public fiatCurrencies: Array<FiatCurrency> = [];
    private static instance: Wallet;
    private digitalCurrenciesService: DigitalCurrenciesService;
    private dataService: DataService;
    private fiatCurrency: FiatCurrency;
    private testnet: boolean;

    constructor(mnemonic?: string) {
        this.digitalCurrenciesService = DigitalCurrenciesService.getInstance();
        this.dataService = new DataService();
        const m = mnemonic || generateMnemonic();
        this.mnemonic = m;
    }

    static getInstance(): Wallet {
        if (!Wallet.instance) {
            throw new Error("Wallet not intialized");
        }
        return Wallet.instance;
    }

    static async init(newSeed?: boolean | string) {
        if (newSeed) {
            await WalletSecureStorage.remove();
        }
        Wallet.instance = new Wallet(typeof newSeed == "string" ? newSeed : undefined);
        await Wallet.instance.load();
    }

    initCurrencies(constructors: Array<IConstructorCurrency>, state: WalletState): Currency[] {
        let currencies: Currency[] = [];

        constructors.sort((a: IConstructorCurrency, b: IConstructorCurrency) => b.sort - a.sort);

        constructors.map((c: any) => {
            let currency: Currency;
            const params: IConstructorCurrency = {
                ...c.currency,
                id: c.currency?._id,
                seed: this.seed,
                icon: c.currency.image?.thumbnail || "",
                fiat: c.currency?.quotation || 0,
                adapters: c.currency?.adapters || {},
                color: c.currency?.color || colors.secondary,
                type: c.currency?.blockchainInfo?.type?.toLowerCase(),
                chainId: c.currency?.blockchainInfo?.chainId,
                priceHistoryDaily: c.currency?.quotationHistory?.daily,
                priceHistoryWeekly: c.currency?.quotationHistory?.weekly,
                enabled: c.defaultEnabled,
                providers: c.currency?.providers || [],
                networkID: c.currency?.blockchainInfo?.bip44CoinType,
                pairs: c.currency?.exchangePairs || [],
                explorerUrl: c.currency?.blockchainInfo?.explorerUrl,
                testnet: c.currency?.network == 'testnet'
            };

            switch (c.currency?.blockchainInfo?.type?.toLowerCase()) {
                case "bitcoin":
                    currency = new BitcoinCurrency({ ...params });
                    break;
                case "evm":
                    currency = new EVMCurrency({ ...params });
                    break;
                case "evml2":
                    currency = new EVMCurrency({ ...params });
                    break;
                case "tron":
                    currency = new TronCurrency({ ...params });
                    break;
                case "centralized":
                    currency = new CentralizedCurrency({ ...params });
                    break;
            }

            if (currency) {
                currencies = [...currencies, currency];
            }


        });

        return currencies;
    }

    initFiatCurrencies(constructors: Array<any>): FiatCurrency[] {
        let fiatCurrencies: FiatCurrency[] = [];
        constructors.map((c: any) => {
            let fiatCurrency: FiatCurrency;
            fiatCurrency = new FiatCurrency({
                id: c?.currency?._id,
                symbol: c?.currency?.symbol,
                sign: c?.currency?.sign,
                icon: c?.currency?.image?.thumbnail || "",
                name: c?.currency?.name,
                fullName: c?.currency?.name,
                decimals: 0,
            });

            if (fiatCurrency) {
                fiatCurrencies = [...fiatCurrencies, fiatCurrency];
            }
        });

        return fiatCurrencies;
    }

    getNetworkType() {
        return this.isTestnet() ? "Testnet" : "Mainnet";
    }

    isTestnet() {
        return this.testnet;
    }

    setIsTestnet(testnet: boolean) {
        this.testnet = testnet;
    }

    async load() {
        const stored = await this.isStateStored();
        const state: WalletState = await (stored ? this.loadState() : this.initState());
        const controlService = ModuleControlService.getInstance();
        // if (!controlService.isInitialized()) {
        //     await controlService.init();
        // }
        const currenciesConstructors = controlService.getCurrencies();
        const fiatCurrenciesConstructors = controlService.getFiatCurrencies();

        let fiatCurrencies = this.initFiatCurrencies(fiatCurrenciesConstructors);
        let currencies = this.initCurrencies(currenciesConstructors, state);

        this.setFiatCurrencies(fiatCurrencies);
        this.setCurrencies(currencies);
        this.setFiatCurrency(this.findFiatCurrencyBySymbol("USD"));

        /*if (!stored) {
            await this.updateAsyncState({ currencies });
        }*/
    }

    getBaseBitcoinAddress() {
        return new BTC({
            seed: this.seed,
            fiatCurrency: this.fiatCurrency,
            testnet: false,
        }).getAddress();
    }

    async loadState(): Promise<WalletState> {
        let secureStorage = <WalletSecureStorage>await WalletSecureStorage.get();
        let asyncStorage = <WalletAsyncStorage>await WalletAsyncStorage.get();

        this.mnemonic = secureStorage.mnemonic;
        this.fiatCurrency = FiatCurrency.fromJSON(asyncStorage.fiatCurrency);
        this.testnet = asyncStorage.testnet ? asyncStorage.testnet : false;
        this.seed = secureStorage.seed ? Buffer.from(secureStorage.seed, "hex") : mnemonicToSeedSync(this.mnemonic);
        this.mainAddress = secureStorage.mainAddress ? secureStorage.mainAddress : this.getBaseBitcoinAddress();

        if (!secureStorage.seed || !secureStorage.mainAddress) {
            await this.updateSecureState({
                seed: this.seed.toString("hex"),
                mainAddress: this.mainAddress,
            });
        }

        return {
            secureStorage,
            asyncStorage,
        };
    }

    async updateSecureState(update: Partial<WalletSecureStorage>) {
        let state = <WalletSecureStorage>await WalletSecureStorage.get();
        const newState = { ...state, ...update };
        await WalletSecureStorage.set(newState);
        return newState;
    }

    async getSeedHistorySecureStorage() {
        let state = <Array<SeedHistorySecureStorage>>await SeedHistorySecureStorage.get();
        return state;
    }

    async updateAsyncState(update: Partial<WalletAsyncStorage>) {
        let state = <WalletAsyncStorage>await WalletAsyncStorage.get();
        const newState = { ...state, ...update };
        await WalletAsyncStorage.set(newState);
        return newState;
    }

    async saveState(prevState?: { asyncStorage: WalletAsyncStorage }): Promise<WalletState> {
        const secureStorage: WalletSecureStorage = {
            mnemonic: this.mnemonic,
            seed: this.seed.toString("hex"),
            mainAddress: this.mainAddress,
        };

        let currencies: Partial<CurrencyStore>[] = this.getCurrencies().map((currency: Currency) => {
            return currency.toJSON();
        });

        //Conservate prev state
        if (currencies.length == 0 && prevState) {
            currencies = (prevState.asyncStorage?.currencies || []).map((c) => {
                return { name: c.name, enabled: c.enabled };
            });
        }

        const asyncStorage: WalletAsyncStorage = {
            fiatCurrency: this.fiatCurrency,
            currencies: currencies,
            testnet: this.testnet,
        };

        await WalletSecureStorage.set(secureStorage);
        await WalletAsyncStorage.set(asyncStorage);

        return {
            secureStorage,
            asyncStorage,
        };
    }

    async isStateStored() {
        return await WalletSecureStorage.get();
    }

    async initState() {
        this.seed = mnemonicToSeedSync(this.mnemonic);
        this.fiatCurrency = this.findFiatCurrencyBySymbol("USD");
        this.testnet = this.testnet;
        this.mainAddress = this.getBaseBitcoinAddress();
        const asyncStorage = await WalletAsyncStorage.get();
        return await this.saveState({ asyncStorage });
    }

    getMainAddress(): string {
        return this.mainAddress;
    }

    getMnemonic(): string[] {
        return this.mnemonic.split(" ");
    }

    setMnemonic(words: Array<string>) {
        this.mnemonic = words.join(" ");
        this.seed = mnemonicToSeedSync(this.mnemonic);
        this.mainAddress = this.getBaseBitcoinAddress();
    }

    getSeed(): Buffer {
        return this.seed;
    }

    getFiatCurrency(): FiatCurrency {
        return this.fiatCurrency;
    }

    async syncBalance() {
        await this.digitalCurrenciesService.syncAllBalances(this);

        store.dispatch(onChange(this));
    }

    async syncData() {
        await this.dataService.syncData(this);

        store.dispatch(onChange(this));
    }

    getBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map(
            (currency: Currency) => (balance += Number(currency.getFiatBalance()) < 0 ? 0 : currency.getFiatBalance())
        );
        return balance;
    }

    getTotalBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map(
            (currency: Currency) =>
                (balance += Number(currency.getFiatTotalBalance()) < 0 ? 0 : currency.getFiatTotalBalance())
        );
        return balance;
    }

    getUnconfirmedBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map((currency: Currency) => (balance += currency.getUnconfirmedFiatBalance()));
        return balance;
    }

    getCurrency(name: string) {
        try {
            return this.currencies.filter((currency: Currency) => currency.getName() === name)[0];
        } catch (err) {
            //console.warn(err);
        }
    }

    getCurrencies() {
        return this.currencies;
    }

    getFiatCurrencies() {
        return this.fiatCurrencies;
    }

    getCurrenciesTypes() {
        let types = [];
        this.currencies.map((c) => {
            if (!types.includes(c.getType())) {
                return types.push(c.getType());
            }
        });
        return types;
    }

    getEnabledCurrencies() {
        if (ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule)) {
            const endabledCurrencies = this.currencies.filter((c) => {
                return c.isEnabled();
            });
            return endabledCurrencies;
        }
        return [];
    }

    getWalletConnectCurrencies() {
        const walletConnectCurrencies = this.currencies.filter((c: Currency) => {
            return c.hasWalletConnect();
        });
        return walletConnectCurrencies;
    }

    getBalancedCurrencies() {
        const currencies = this.currencies.filter((c: Currency) => {
            return c.getBalance() > 0 && c.isEnabled();
        });
        return currencies;
    }

    getNftSupportedCurrencies() {
        const nftSupportedCurrencies = this.currencies.filter((c: Currency) => {
            return c.hasNftSupport();
        });

        return nftSupportedCurrencies;
    }

    setCurrencies(currencies: Array<Currency>) {
        this.currencies = currencies;
        store.dispatch(setCurrencies(this.getEnabledCurrencies()));
    }

    setEnabledCurrencies(currencies) {
        currencies?.map((c) => {
            const currency = this.findCurrencyById(c?.currency?._id);
            if (currency) {
                currency.setEnabled(c.defaultEnabled);
            }
        });
    }

    setFiatCurrencies(fiatCurrencies) {
        this.fiatCurrencies = fiatCurrencies;
    }

    setFiatCurrency(fiatCurrency: FiatCurrency) {
        this.fiatCurrency = fiatCurrency;
        store.dispatch(setFiatCurrency(fiatCurrency));
    }

    findCurrencyByName(name: string): Currency {
        name = name.toLowerCase();
        return this.getCurrencies().find((currency) => currency.getName().toLowerCase() === name);
    }

    findFiatCurrencyById(id: string) {
        id = id.toLowerCase();
        let fiatCurrency = null;
        fiatCurrency = this.getFiatCurrencies().find((f) => f.getId().toLowerCase() === id);
        return fiatCurrency;
    }

    findFiatCurrencyBySymbol(symbol: string) {
        symbol = symbol.toLowerCase();
        let fiatCurrency = null;
        fiatCurrency = this.getFiatCurrencies().find((f) => f.getSymbol().toLowerCase() === symbol);
        return fiatCurrency;
    }

    findCurrencyById(id: string): Currency {
        id = id?.toLowerCase();
        let currency = null;
        currency = this.getCurrencies().find((currency) => currency.getId().toLowerCase() === id);

        if (!currency) {
            currency = this.getCurrencies().find((currency) => currency.getAdapter("xo")?.toLowerCase() === id);
        }
        return currency;
    }

    findCurrencyByChainId(chainId: number): Currency {
        const c = this.getCurrencies().find((currency) => currency.getChainId() == chainId);

        return c?.getUnderlyingCurrency() || null;
    }

    findCurrencyByBlockchain(blockchain: string, isTestnet: boolean = false): Currency {
        return this.getCurrencies().find(
            (currency) => 
                currency.getBlockchain() === blockchain && 
                currency.getKind() == "NATIVE" &&
                currency.isTestnet() == isTestnet
        );
    }

    findCurrencyBySymbol(symbol: string): Currency {
        return this.getCurrencies().find((currency) => currency.getSymbol() === symbol);
    }

    findCurrencyByType(type: string): Currency {
        return this.getCurrencies().find((currency) => currency.getType() === type);
    }

}
