import BoldText from "@base/BoldText";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import LottieBase from "@components/animations/Lottie";
import TypeWriter from "@components/text/TypeWriter";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import GameButton from "@games/components/GameButton";
import QuizGame, { GameStatus } from "@games/core/QuizGame";
import { QuizAnswer, QuizQuestion } from "@games/types/Quiz";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import Finished from "./Finished";

const { t } = i18n;

interface Props {
    quiz?: QuizGame;
}

interface State {
    question: QuizQuestion;
    answer: QuizAnswer;
    showAnswers: boolean;
    index?: number;
}

export class _Questions extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            question: null,
            answer: null,
            showAnswers: false,
            index: 0,
        };
    }

    componentDidMount() {
        this.setState({ question: this.props?.quiz?.getQuestions()?.[this.state.index] });
    }

    onPressAnswer(answer: QuizAnswer) {
        this.setState(
            { answer: answer, index: this.state.index + 1 },

            () => {
                this.props?.quiz?.setUserAnswer(this.state.question?._id, answer?._id);
                setTimeout(async () => {
                    if (this.props?.quiz?.getQuestions()?.length <= this.state.index) {
                        return await this.props.quiz?.onQuizGameOver();
                    }
                    return this.setState({
                        question: this.props?.quiz?.getQuestions()?.[this.state.index],
                        showAnswers: false,
                    });
                }, 1500);
            }
        );
    }

    render() {
        return (
            <View
                style={{
                    flex: 1,
                    alignContent: "center",
                    alignItems: "center",
                    // justifyContent: "center",
                    paddingTop: 50,
                }}
            >
                {this.state.question && (
                    <View style={{ width: "100%" }}>
                        <View style={{ minHeight: 150 }}>
                            <TypeWriter
                                textToType={this.state.question?.question}
                                fontSize={18}
                                typingSpeed={40}
                                align={"center"}
                                onTypingEnd={() => this.setState({ showAnswers: true })}
                            />
                        </View>
                        {this.state.showAnswers && (
                            <View>
                                {this.state.question?.answers?.map((a: QuizAnswer) => {
                                    return (
                                        <GameButton
                                            key={a?._id}
                                            style={{ marginTop: 20 }}
                                            title={a?.answer}
                                            fontSize={18}
                                            onPress={() => this.onPressAnswer(a)}
                                            vibrate={true}
                                            animation={a?._id == this.state.answer?._id ? this.props.quiz.getButtonAnimation() : false}
                                            selected={a?._id == this.state.answer?._id}
                                        ></GameButton>
                                    );
                                })}
                            </View>
                        )}
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const Questions = connect(mapStateToProps, mapDispatchToProps)(_Questions);

export default Questions;
