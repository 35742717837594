import PressableBase from "@base/PressableBase"
import RadiusButton from "@base/RadiusButton"
import RegularText from "@base/RegularText"
import Row from "@base/Row"
import Icon from "@components/icons"
import { colors } from "@styles/globalStyles"
import { trimHelper } from "@utils/helpers/chat/chat.helper"
import { useState } from "react"
import { Linking, View } from "react-native"
import * as Clipboard from "expo-clipboard";
import i18n from "@i18n/i18n";
const { t } = i18n;

export const ExplorerComponent = ({explorerUrl, txHash, styles={}}) => {
    const [copyData, setCopyData ] = useState('');

    const onPressCopy = async (data) => {
        await Clipboard.setStringAsync(data);
        setCopyData(data);
        setTimeout(() => {
            setCopyData("")
        }, 3000);
    };
    
    return (
        <View style={styles}>
            <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
            <Row style={{ alignItems: "baseline" }}>
                {copyData == txHash ? (
                    <RegularText color={colors.secondary} align="left">
                        {t("copied")}
                    </RegularText>
                ) : (
                    <Row>
                        <RegularText color={colors.grey} align="left" selectable={true}>
                            {trimHelper(txHash, 35, true)}
                        </RegularText>
                        <PressableBase
                            onPress={() => onPressCopy(txHash)}
                            style={{ paddingVertical: 2 }}
                        >
                            <Icon
                                size={16}
                                name="content-copy"
                                color={colors.grey}
                                style={{ marginLeft: 10 }}
                            />
                        </PressableBase>
                    </Row>
                )}
            </Row>
            <Row style={{ paddingTop: 5 }}>
                <RegularText
                    onPress={() =>
                        `${explorerUrl}/${txHash}`
                    }
                    fontSize={14}
                    color={colors.blue}
                >
                    {trimHelper(explorerUrl, 20)}
                </RegularText>
                <RadiusButton
                    style={{ paddingVertical: 2, marginLeft: 5 }}
                    color={colors.grey}
                    fontSize={12}
                    title={"Blockchain Explorer"}
                    onPress={() =>
                        Linking.openURL(
                            `${explorerUrl}/${txHash}`
                        )
                    }
                ></RadiusButton>
            </Row>
        </View>
        
    )
}