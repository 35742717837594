import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Label from "@base/Label";
import Card from "@base/Card";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import NFT from "@custom-types/NFTModel";
import { Client } from "@custom-types/Client";
import NFTService from "@core/services/NFTService";
import BotCard from "@base/BotCard";
import { Route } from "@react-navigation/native";
import { ChatService } from "@core/services/ChatService";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
    navigation: NavigationType;
    route: Route<
        string,
        {
            currency: string;
            nft: NFT;
            skeleton: {
                from: string;
                to: string;
                addressTo: string;
                value: string;
                nonce: string;
                data: string;
                gasPrice: number;
                fee: number;
                estimateGas: number;
                chainId: number;
                gasLimit: number;
            };
        }
    >;
    client: Client;
}

interface State {
    fee: string;
    sendingTo: string;
    sendingFrom: string;
}

const { t } = i18n;

export class _ConfirmTransferScreen extends Component<Props, State> {
    private currency: Currency;
    constructor(props: Props) {
        super(props);
        this.onTransfer = this.onTransfer.bind(this);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.route.params?.currency);
        const skeleton = this.currency.getImplementation().parseSkeleton(this.props.route.params?.skeleton);
        this.state = {
            sendingTo: skeleton.sendingTo,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton?.feeData?.amount,
        };
    }

    async onTransfer() {
        store.dispatch(loading());
        try {
            const skeleton = this.props.route.params?.skeleton;

            const nft = this.props.route.params?.nft;

            const resp = await NFTService.getInstance().sendTransferTransaction(skeleton, this.currency, nft);
      
            if (resp) {
                store.dispatch(showPopup({ type: "SUCCESS" }));
                await this.sendMessage();
            }

            
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
        } catch (e) {
            console.warn(e);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
        store.dispatch(ready());
    }

    async sendMessage() {
        const service: ChatService = await ChatService.getInstance();
        service.sendCustomMessage();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between", paddingVertical: 20 }}>
                    <View>
                        <BotCard title={t("hi")} message={t("info_card_confirm_transfer")}></BotCard>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_from")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingFrom}
                            </BoldText>
                        </Card>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_to")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingTo}
                            </BoldText>
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("fee")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.state.fee} {this.currency.getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.currency.toFiat(Number(this.state.fee))}`}
                                align="center"
                                fontSize={12}
                            />
                        </Card>
                    </View>
                    <InlineButton onPress={this.onTransfer} title={t("confirm_transaction")} />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmTransferScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmTransferScreen);

export default ConfirmTransferScreen;

const styles = StyleSheet.create({
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});
