import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import QuizService from "@games/services/QuizService";
import i18n from "@i18n/i18n";
import { hideScanner, loading, ready, showSnackbar } from "@store/actions/global";
import store from "@store/index";

const { t } = i18n;

export const isGame = (navigation, qrCode) => {};

export const getQuizGame = async (navigation, id) => {
    if (!ModuleControlService.getInstance().isModuleEnabled(Modules.gamesModule)) {
        store.dispatch(hideScanner());
        store.dispatch(
            showSnackbar({
                type: "ERROR",
                message: t("error_module_disabled"),
            })
        );
        return;
    }
    store.dispatch(hideScanner());

    store.dispatch(loading());
    const QuizGame = await QuizService.getInstance().getQuizAsync(id || "");

    if (!QuizGame) {
        store.dispatch(ready());
        return;
    }

    navigation.navigate("Games", {
        screen: "Quiz",
        params: { quiz: QuizGame },
    });
};
