import FlatListBase from "@base/FlatListBase";
import { ActivityModel } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ActivityNavigatorScreens } from "@navigation/ActivityNavigator";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import React, { Component } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { connect } from "react-redux";

import { getActivityCard } from "@utils/helpers/activity/activity.helper";
import ActivityCardQuizGame from "./components/ActivityCardGameReward";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    activity: Array<ActivityModel>;
    paginator;
}

interface State {}

const { t } = i18n;

export class _ActivitySection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    renderItem = ({ item }) => {
        if (!item) return;
        return getActivityCard(item, this.props.navigation);
    };

    navigateToSection = () => {
        this.props.navigation.navigate("Activity", {
            screen: ActivityNavigatorScreens.ActivityMain.routeName,
        });
    };

    render() {
        return (
            this.props.activity.length > 0 && (
                <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                    <SectionTitle
                        onPress={this.navigateToSection}
                        title={t("activity").toUpperCase()}
                        icon={"🔎"}
                        seeMore={this.props.paginator?.totalDocs > 2}
                        {...this.props}
                    ></SectionTitle>

                    <FlatListBase
                        scrollEnabled={false}
                        data={this.props.activity.slice(0, 2)}
                        renderItem={(item) => this.renderItem(item)}
                        keyExtractor={(item) => item?.id || item?._id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        contentContainerStyle={{ marginTop: 10 }}
                        extraData={this.props.paginator?.totalDocs}
                    ></FlatListBase>
                </View>
            )
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        activity: state.activity.activity,
        paginator: state.activity.paginator,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivitySection = connect(mapStateToProps, mapDispatchToProps)(_ActivitySection);

export default ActivitySection;
