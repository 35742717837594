import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, setProgresiveLoader, showPopup, showSnackbar } from "@store/actions/global";
import { Platform } from "react-native";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import NFT from "@custom-types/NFTModel";
import { ModuleControlService, NFTsModules } from "./ModuleControlService";

const { t } = i18n;

export const default_currency_NFTS: string = "MATIC";

export default class NFTService {
    private static instance: NFTService;

    constructor() { }

    static getInstance(): NFTService {
        if (!NFTService.instance) {
            NFTService.instance = new NFTService();
        }
        return NFTService.instance;
    }

    async editDraft(data: any) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${data.blockchain}/${data.network}/nft/draft/${data.id}`;

            const body = {
                name: data.name,
                fixedValues: data.fixedValues,
                cashbackValues: data.cashbackValues,
                attributes: data.attributes,
                description: data.description || "",
            };

            const resp = await axios.patch(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async createImageNFT(data: any) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${data.blockchain}/${data.network}/nft/draft`;
            const form = new FormData();
            if (Platform.OS == "web") {
                const base64 = await fetch(data.uri);
                const blob = await base64.blob();

                form.append("image", blob);
            } else {
                form.append("image", {
                    uri: data.uri,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any);
            }

            form.append("addressFrom", data.addressFrom);
            form.append("name", data.name);
            form.append("fixedValues", data.fixedValues);
            form.append("cashbackValues", data.cashbackValues);
            form.append("attributes", data.attributes);
            form.append("description", data.description || "");

            const resp = await axios.post(url, form, {
                headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async createVideoNFT(data: any) {
        try {
            store.dispatch(setProgresiveLoader(0));

            let url = `${getEnv("API_URL")}${data.blockchain}/${data.network}/nft/draft`;

            const form = new FormData();
            if (Platform.OS == "web") {
                const base64 = await fetch(data.uri_video);
                const blob = await base64.blob();
                form.append("video", blob);
            } else {
                form.append("video", {
                    uri: data.uri_video,
                    type: "video/mp4",
                    name: "file" + Math.random(),
                } as any);
            }

            if (Platform.OS == "web") {
                const base64 = await fetch(data.uri_poster);
                const blob = await base64.blob();
                form.append("image", blob);
            } else {
                form.append("image", {
                    uri: data.uri_poster,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any);
            }

            form.append("addressFrom", data.addressFrom);
            form.append("name", data.name);
            form.append("fixedValues", data.fixedValues);
            form.append("cashbackValues", data.cashbackValues);
            form.append("attributes", data.attributes);
            form.append("description", data.description || "");

            const resp = await axios.post(url, form, {
                headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    let procent = Math.round((progressEvent.loaded / data.size) * 100);
                    store.dispatch(setProgresiveLoader(procent));
                },
            });

            store.dispatch(ready());

            return resp.data;
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: error.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async removeDraft(currency: Currency, id) {
        try {
            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/${id}`;

            const resp = await axios.delete(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp;
        } catch { }
    }

    async mintNFT(data: any) {
        try {
            let url = `${getEnv("API_URL")}${data.blockchain}/${data.network}/nft/mint/${data.id}`;
            let body = {};
            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            store.dispatch(ready());

            return resp.data;
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: error.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async sendTransaction(skeleton, currency: Currency, id) {
        try {
            const signedTransaction = await currency.signTransaction(skeleton);

            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/broadcast/${id}`;
            let body = {
                txs: signedTransaction,
                skeleton,
            };
            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp;
        } catch (e: any) { }
    }

    async getUserNFTs(clientId: string) {
        try {
            let url = `${getEnv("API_URL")}all/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/client/${clientId}`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp.data;
        } catch (e: any) {
            console.warn(e);
        }
    }

    async getNFT(id: string, blockchain: string, showLoader: boolean) {
        try {
            let url = `${getEnv("API_URL")}${blockchain}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/${id}`;
            if (showLoader) {
                store.dispatch(loading());
            }
            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            if (showLoader) {
                store.dispatch(
                    showSnackbar({
                        type: "ERROR",
                        message: t("an_error_has_occurred_open_NFT"),
                    }),
                );
            }
        }
    }

    async searchNFTs(name: string) {
        try {
            let url = `${getEnv("API_URL")}all/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace?name=${name}`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp.data;
        } catch (e: any) {
            console.warn(e);
        }
    }

    async getAllNFTs(page: number, showLoader?: boolean) {
        try {
            if (!ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.marketplace)) {
                return;
            }

            showLoader && store.dispatch(loading());
            let url = `${getEnv("API_URL")}all/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace?page=${page}`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            console.warn(e);
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async requestApproveNFT(currency: any, tokenId: number, contractAddress: string) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/approve/marketplace`;
            let body = {
                addressFrom: currency.getAddress(),
                tokenId: tokenId,
                contractAddress: contractAddress,
            };
            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            console.warn(e);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async requestSaleNFT(currency: any, tokenId: number | string, nftContractAddress: string, price: number) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace/item`;
            let body = {
                nftContractAddress: nftContractAddress,
                price: price,
                addressFrom: currency.getAddress(),
                tokenId: parseInt(tokenId.toString()),
            };

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async requestBuyNFT(currency: any, tokenId: number, nftContractAddress: string) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace/buy`;
            let body = {
                nftContractAddress: nftContractAddress,
                addressFrom: currency.getAddress(),
                tokenId: tokenId,
            };

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            console.warn(e.response.data.message);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async requestCancelNFT(currency: any, tokenId: number, nftContractAddress: string) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace/cancel`;
            let body = {
                nftContractAddress: nftContractAddress,
                addressFrom: currency.getAddress(),
                tokenId: tokenId,
            };

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            console.warn(e);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async reportNFT(currency: any, id: any) {
        try {
            store.dispatch(loading());

            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/${id}/report`;

            let body = {};

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            store.dispatch(ready());
            console.warn(e);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async getFeeService(blockchain: string) {
        try {
            let url = `${getEnv("API_URL")}${blockchain}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace/fee`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp.data;
        } catch (e: any) {
            console.warn(e);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async getUserHistory() {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}all/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/marketplace/history`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });
            store.dispatch(ready());
            return resp.data;
        } catch (e: any) {
            console.warn(e);
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    async transferNFT(NFT: NFT, addressFrom: string, addressTo: string) {
        try {
           
            let url = `${getEnv("API_URL")}${NFT.blockchain}/${NFT.network}/nft/safe-transfer-from/${NFT._id}`;

            let body = { addressFrom: addressFrom, addressTo: addressTo, tokenId: NFT.tokenId * 1 };

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            store.dispatch(ready());

            return resp.data;
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: error.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async sendTransferTransaction(skeleton, currency: Currency, nft: NFT) {
        try {
            const skeletonRaw = {transactionData: skeleton}
            const signedTransaction = await currency.signTransaction(skeletonRaw);

            let url = `${getEnv("API_URL")}${currency.getBlockchain()}/${Wallet.getInstance()
                .getNetworkType()
                .toLowerCase()}/nft/broadcast/transfer/${nft._id}`;
            let body = {
                txs: signedTransaction,
                skeleton,
            };
            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp;
        } catch (e: any) {

            store.dispatch(showPopup({type: "ERROR", message: t("an_error_has_occurred_open_NFT") }))
         }
    }
}
