import CurrencyImplementation, { Skeleton } from "../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as ethUtil from "ethereumjs-util";
import HDNode from "hdkey";
import Web3 from "web3";
import rsk3 from "@rsksmart/rsk3";
import { format } from "date-fns";
import Wallet from "@core/wallet/Wallet";
import Common from "ethereumjs-common";
import store from "@store/index";
const EthereumTx = require("ethereumjs-tx").Transaction;
const ethers = require("ethers");

export default class XOImplementation extends CurrencyImplementation {
    async signMessage(addressNode: PathNode, hexMessage: string) {
        //@TODO assert >> this.currency.getAddress() == account
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);
        return await wallet.signMessage(hexMessage);
    }

    generateAddress(addressNode: PathNode, options?: { chainId?: number }) {
        const chainId = options.chainId;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let address = "0x" + ethUtil.privateToAddress(privateKey).toString("hex");

        return Web3.utils.toChecksumAddress(address);
    }

    async signTransaction(addressNode: PathNode, skeleton) {
        const rawTx = skeleton?.transactionData;
        return this.signMessage(addressNode, JSON.stringify(rawTx));
    }

    parseSkeleton(skeletonData): Skeleton {
        const amount = this.currency.fromDecimals(
            skeletonData?.extraTransactionData?.value || skeletonData?.transactionData?.value || 0
        );
        const sendingFrom = skeletonData?.from || skeletonData?.addressFrom || "";
        const sendingTo = skeletonData?.extraTransactionData?.to || skeletonData?.transactionData?.to || "";
        const underlyingCurrency = this.currency.getUnderlyingCurrency();

        const feeData = {
            amount: underlyingCurrency.fromDecimalsToString(skeletonData.feeData?.amount || 0),
            digitalCurrencyId: underlyingCurrency?.getId(),
        };

        const skeleton: Skeleton = {
            amount: amount,
            sendingFrom: sendingFrom,
            sendingTo: sendingTo,
            feeData: feeData,
        };

        return skeleton;
    }

    isValidAddress(address: string) {
        return Web3.utils.isAddress(address.toLowerCase());
    }
}
